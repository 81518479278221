import React, { useMemo } from 'react';
import { price } from 'simple';
import { $accommodation } from 'stores';

const AccountBalancePlate = ({ className }) => {
    const accountBalance = useMemo(
        () =>
            $accommodation.search.request.surrogateActiveAgencyContractKind ===
                'VirtualAccountOrCreditCardPayments' && $accommodation.search.request.balance,
        [$accommodation.search.request]
    );

    if (!accountBalance) {
        return null;
    }

    return (
        <div className={'account-balance ' + className}>
            <span className="icon-information icon" />
            <span>Account Balance:</span>
            <span className="price">{price(accountBalance)}</span>
        </div>
    );
};

export default AccountBalancePlate;
