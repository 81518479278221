import React from 'react';
import { date, PassengersCount } from 'simple';
import { SEARCH_STATUSES } from 'enum';
import { $accommodation } from 'stores';
import AccountBalancePlate from '../search-filters/account-balance-plate';

const SearchHeading = ({ isAgencySearch, search, request, taskState }) => {
    const agencyAndAgentName = [
        $accommodation.search.request.surrogateAgencyName,
        $accommodation.search.request.surrogateAgentName,
    ]
        .filter(Boolean)
        .join(', ');

    const Nights = __plural(search.numberOfNights, 'Night');

    const Guests = (
        <PassengersCount
            adults={request.roomDetails.reduce((res, item) => res + item.adultsNumber, 0)}
            children={request.roomDetails.reduce((res, item) => res + item.childrenNumber, 0)}
        />
    );

    return (
        <div className="head">
            {isAgencySearch ? (
                <div className="title agency-search-title">
                    <h2>Search Results For: {agencyAndAgentName}</h2>

                    <AccountBalancePlate />
                </div>
            ) : (
                <>
                    <div className="request">
                        <span>
                            {date.format.day(date.day(request.checkInDate))} –{' '}
                            {date.format.day(date.day(request.checkOutDate))}
                        </span>{' '}
                        • <span>{Nights}</span> • <span>{Guests}</span>
                    </div>

                    <div className="title">
                        <h2>
                            <div>
                                {!request.htIds?.[0]?.includes('Accommodation_') && 'Accommodations in' + ' '}
                                {request.destination}{' '}
                            </div>
                            {!!search.resultCount && (
                                <span>
                                    (
                                    {search.availabilityCount !== undefined
                                        ? search.availabilityCount
                                        : search.resultCount}
                                    {!SEARCH_STATUSES.isFinished(taskState) && '+'})
                                </span>
                            )}
                        </h2>
                    </div>
                </>
            )}
        </div>
    );
};

export default SearchHeading;
