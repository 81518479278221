import React, { useState, useRef, useEffect } from 'react';

const Popover = ({ title, children, Content }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpen((prevState) => !prevState);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div ref={ref} onClick={handleOpen} className="popover-wrapper">
            <div className="popover-arrow" data-open={open} />

            <div className="children-wrapper">{children}</div>

            <div className="popover" data-open={open}>
                {title && <div className="title">{title}</div>}
                <div className="content">{Content}</div>
            </div>
        </div>
    );
};

export default Popover;
