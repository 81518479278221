import React, { useEffect, useRef, useState } from 'react';
import { Marker } from 'react-leaflet';
import { observer } from 'mobx-react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import ModalTemplate from 'common/template/modal-template';
import Map from '../map';
import { customIcon } from '../custom/custom-icon';
import HotelMapMarkerPopup from './hotel-map-marker-popup';
import { $accommodation } from 'stores';

const HotelMapModal = observer(({ latitude, longitude, name, rating, ...props }) => {
    const { search } = $accommodation;
    const markerRef = useRef(null);
    const [searchIdNotified, setSearchIdNotified] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            // markerRef.current?.
            if (!markerRef.current?.isPopupOpen()) {
                markerRef.current?.openPopup();
            }
        }, 100);
    }, [props.isOpen]);

    useEffect(() => {
        if (props.isOpen && searchIdNotified !== search?.id) {
            API.get({
                url: apiMethods.A_SEARCH_TWO_MAP_OPEN(search.id),
            });
            setSearchIdNotified(search?.id);
        }
    }, [props.isOpen, search?.id, searchIdNotified, setSearchIdNotified]);

    if (!props.isOpen) {
        return null;
    }

    return (
        <ModalTemplate addClass="map-modal single" withCross={false} {...props}>
            <div className="button small close-button shadow" onClick={props.onClose}>
                <span className="icon icon-close" /> Close Map
            </div>
            <Map center={[latitude, longitude]} searchId={search?.id}>
                <Marker position={[latitude, longitude]} icon={customIcon} ref={markerRef}>
                    <HotelMapMarkerPopup name={name} rating={rating} onClose={props.onClose} />
                </Marker>
            </Map>
        </ModalTemplate>
    );
});

export default HotelMapModal;
