import React from 'react';
import { useTitle } from 'htcore';
import Header from '../components/header';
import Footer from '../components/footer';
import Consent from '../components/consent';
import useGoogleAnalytics from '../hooks/use-google-analytics';

const PrivacyPage = () => {
    useTitle('Privacy Policy');
    useGoogleAnalytics();

    return (
        <div className="lg">
            <div className="container">
                <Header />
                <section className="section-wrapper document block">
                    <h1>Privacy Policy</h1>

                    <p>
                        HappyTravelDotCom Travel and Tourism LLC (“<strong>Happy Travel</strong>”, “
                        <strong>our</strong>”, “<strong>us</strong>” or “<strong>we</strong>”) is committed to
                        protecting the privacy of your personal data.
                    </p>
                    <p>
                        Throughout this privacy notice, the terms “<strong>you</strong>” and “
                        <strong>your</strong>” refer to (i) employees and representatives of travel companies
                        (such as travel agents, tour operators and other wholesalers or airlines providing
                        ground services to customers; each a “<strong>Travel Business</strong>”) that wish to
                        purchase one or more travel components (such as accommodation, transfers, sightseeing
                        tours, excursions or meals out; together “<strong>Travel Components</strong>”), and
                        (ii) end users of Travel Components (whose personal data may be provided to us
                        indirectly, as further described below).
                    </p>
                    <p>
                        This notice describes how HappyTravel “processes” (e.g., collects, stores, uses,
                        transmits) and protects your personal data that we collect and receive through our
                        online booking platform. This notice also explains your various legal rights in
                        connection with our processing of your personal data.
                    </p>

                    <h2>1. WHO WE ARE</h2>
                    <p>
                        HappyTravel is responsible for processing your personal data in connection with our
                        online booking platform and is the “data controller” of the processing described in
                        this notice.
                    </p>
                    <p>
                        Please contact us if you have any questions about the contents of this privacy notice,
                        including requests to exercise your legal rights.
                    </p>
                    <h2>2. PERSONAL DATA WE COLLECT</h2>
                    <p>
                        Personal data is information relating to an individual, from which that person can be
                        identified.
                    </p>
                    <p>We may process different types of your personal data, such as:</p>
                    <ul style={{ paddingBottom: 0 }}>
                        <li>
                            <strong>Identity Data</strong> includes name, title, marital status, date of
                            birth, gender, phone number, email address, location, postal address, information
                            contained in government-issued identification documents such as passports,
                            national ID cards and driver licenses (including nationality, identification
                            numbers, social security numbers and your photograph).
                        </li>
                        <li>
                            <strong>Financial Data</strong> includes bank account and credit card details.
                        </li>
                        <li>
                            <strong>Transaction Data</strong> includes details about Travel Components or
                            other products and services purchased.
                        </li>
                        <li>
                            <strong>Usage</strong> <strong>Data</strong> includes information about how you
                            use our website, such as internet protocol (IP) address, mouse tracking, links
                            clicked, your login data, browser type and version, time zone setting and
                            location, browser plug-in types and versions, operating system and platform, and
                            other technology on the devices you use to access this website.
                        </li>
                        <li>
                            <strong>Profile Data</strong> includes your username and password, your interests,
                            preferences, and feedback responses.
                        </li>
                        <li>
                            <strong>Marketing and Communications Data</strong> includes your preferences in
                            receiving marketing information from us and our partners.
                        </li>
                    </ul>
                    <p>
                        We may also process <strong>Aggregated </strong>or<strong> Anonymised Data </strong>
                        (i.e., data that can no longer be used to identify you), for any purpose.
                    </p>
                    <p>
                        <strong>Special Categories of Personal Data</strong> include information revealing
                        racial or ethnic origin, political opinions, religious or philosophical beliefs, trade
                        union membership, sex life, sexual orientation, genetic data, biometric data and data
                        concerning health.
                    </p>
                    <p>
                        In certain limited circumstances, we may process Special Categories of Personal Data
                        (for example, a religious meal preference). We will process such Special Categories of
                        Personal Data only as permitted under applicable laws and regulations and where we
                        have a basis for doing so. For instance, it may be possible for us to process Special
                        Categories of Personal Data where it is necessary for the establishment, exercise or
                        defense of legal claims, or for reasons of substantial public interest.&nbsp; We may
                        also seek consent to process Special Categories of Personal Data, in which case we
                        will provide you with further information about our data processing activities, as
                        necessary.
                    </p>
                    <h2>3. HOW WE COLLECT YOUR PERSONAL DATA</h2>
                    <p>We use different methods to collect data from and about you, including:</p>
                    <ol>
                        <li>
                            <strong>Direct interactions. </strong>You may give us your Identity, Financial,
                            Transaction, Profile and Marketing and Communications Data directly, including
                            when you:
                            <ul>
                                <li>Enquire about or purchase one or more Travel Components.</li>
                                <li>Create an account on our online platform.</li>
                                <li>Provide feedback.</li>
                                <li>Subscribe to updates, marketing and promotional information.</li>
                                <li>
                                    Correspond with us, whether by post, phone or electronic mail (including
                                    email).
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Indirect interactions. </strong>We may receive your personal data
                            indirectly, including from:
                            <ul>
                                <li>Travel Businesses.</li>
                                <li>Travel Component providers.</li>
                                <li>Credit reference agencies and payment service providers.</li>
                                <li>
                                    Professional advisers, such as legal, regulatory, tax and information
                                    technology advisers.
                                </li>
                                <li>Marketing and advertising partners.</li>
                                <li>Market research providers.</li>
                                <li>Customer support service providers.</li>
                                <li>Fraud detection agencies.</li>
                                <li>
                                    Cookies or similar technologies, in the event that such are utilised on
                                    our online booking platform.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <h2>4. HOW WE USE YOUR PERSONAL DATA</h2>
                    <h3>Purposes of Processing</h3>
                    <p>
                        We process your personal data for one or more of the purposes listed below (or in ways
                        that are not incompatible with the purposes listed below).
                    </p>
                    <ol style={{ paddingBottom: 0 }}>
                        <li>
                            To <strong>provide our services</strong>, including to:
                            <ul>
                                <li>
                                    Communicate with Travel Businesses and providers of Travel Components to
                                    enquire about and complete your reservation.
                                </li>
                                <li>Administer your purchase/reservation of Travel Components.</li>
                                <li>
                                    Send confirmation, modification and reminder communications to you and to
                                    Travel Businesses and providers of Travel Components.
                                </li>
                                <li>Process payments.</li>
                            </ul>
                        </li>
                        <li>
                            To<strong> communicate with you</strong>, including to:
                            <ul>
                                <li>
                                    Remind you to continue and confirm your reservation (where a reservation
                                    has not been finalised).
                                </li>
                                <li>Handle and respond to your enquiries and requests.</li>
                                <li>Solicit and receive feedback and reviews about your experience.</li>
                                <li>Send security alerts or travel updates.</li>
                                <li>Invite you to interact with our online platform forum.</li>
                                <li>Request your participation in market research initiatives.</li>
                            </ul>
                        </li>
                        <li>
                            For <strong>business administration</strong> purposes, including to:
                            <ul>
                                <li>Create your user profile.</li>
                                <li>Authenticate and verify your identity.</li>
                                <li>Record your enquiries and requests, and our responses.</li>
                                <li>Conduct financial worthiness assessments.</li>
                                <li>Operate our online platform and its profiles.</li>
                                <li>
                                    Maintain our Travel Business and Travel Component provider contact
                                    database.
                                </li>
                                <li>
                                    Manage our relationship with Travel Businesses and/or providers of Travel
                                    Components.
                                </li>
                                <li>
                                    Undertake business planning, strategy and/or organisation (including to
                                    evaluate, enter into agreements for and complete corporate transactions
                                    such as partnerships, joint ventures, mergers, disposals, acquisitions or
                                    reorganisations of corporations or businesses, whether actual or
                                    contemplated).
                                </li>
                            </ul>
                        </li>
                        <li>
                            To<strong> improve and secure our online platform</strong>, including to:
                            <ul>
                                <li>Test and troubleshoot the features of our online platform.</li>
                                <li>
                                    Enhance the functionality, security and quality of our online platform.
                                </li>
                                <li>Complete statistical and trend analysis.</li>
                                <li>Undertake system management.</li>
                            </ul>
                        </li>
                        <li>
                            For <strong>legal </strong>purposes, including to:
                            <ul>
                                <li>Pursue, defend, handle and resolve legal disputes.</li>
                                <li>Assist with regulatory investigations.</li>
                                <li>Comply with lawful requests from law enforcement agencies.</li>
                                <li>
                                    Detect and prevent fraud, crime, money laundering and other illegal or
                                    unwanted activity.
                                </li>
                                <li>Adhere to audit and reporting requirements.</li>
                            </ul>
                        </li>
                    </ol>
                    <h3>Basis for Processing</h3>
                    <p>
                        Processing for purpose (a) is undertaken because it is necessary for the performance
                        of a contract. Personal data may be processed for purposes (b), (c) and (d) on the
                        basis of our legitimate interests to provide, promote and improve our services. Your
                        personal data may be processed for purpose (e) on the basis that it may be necessary
                        for compliance with a legal obligation, for performance of a task carried out in the
                        public interest and for our legitimate interests in defending HappyTravel’s business
                        interest and complying with all legal and regulatory requirements to which we are
                        subject.
                    </p>
                    <p>
                        When processing personal data to serve our legitimate interests, we always balance
                        your interests and fundamental rights and freedoms to ensure they are never
                        overridden.
                    </p>
                    <p>
                        Generally, we do not rely on consent as a legal basis for processing your personal
                        data. In limited circumstances, we ask for your consent for specific activities, for
                        example, sending third party direct marketing communications to you via email or text
                        message. You have the right to withdraw consent at any time by contacting us using the
                        information in the Contact Details section below.
                    </p>
                    <h3>Marketing</h3>
                    <p>
                        We will only send you marketing information where we can do so lawfully. We may send
                        you marketing communications where you have requested information from us or
                        previously purchased Travel Components through our online platform, and have not opted
                        out of receiving such marketing material. We may also be able to send you marketing
                        information where you have consented to receiving such material. You can choose not to
                        receive marketing communications at any time.
                    </p>
                    <h3>Failure to Provide Personal Data</h3>
                    <p>
                        Where we need to collect personal data by law, under the terms of a contract we have
                        or are trying to enter with a Travel Business or provider of Travel Components, or
                        otherwise in connection with our services, your failure to provide that personal data
                        when requested may mean we are unable to provide our services to you.
                    </p>
                    <h2>5. DISCLOSURES OF YOUR PERSONAL DATA</h2>
                    <p>
                        We may share your personal data (for the purposes listed above) with the following
                        categories of third parties:
                    </p>
                    <ol>
                        <li>Travel Businesses and Providers of Travel Components.</li>
                        <li>
                            Professional advisors such as legal, regulatory, tax and information technology
                            advisers.
                        </li>
                        <li>Affiliates of HappyTravel.</li>
                        <li>
                            Third party service providers / business partners (who provide us with support
                            services including customer support, marketing and advertising, market research,
                            fraud detection, payment and credit reference services and IT support).
                        </li>
                        <li>
                            Law enforcement agencies, regulatory or tax authorities and other governmental or
                            public agencies.
                        </li>
                        <li>
                            Counterparties in the context of corporate transactions, including actual or
                            potential acquirers of, or investors in, our business.
                        </li>
                    </ol>
                    <h2>6. LOCATION / INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</h2>
                    <p>
                        HappyTravel’s business is located in Dubai. In order to provide our services, we use
                        external service providers (including for web hosting), whose servers are located in
                        jurisdictions outside Dubai (including within the European Union).&nbsp; When you
                        provide personal data to us, it may be electronically routed through servers in such
                        other jurisdictions and subsequently access by us in Dubai. We access your personal
                        data in compliance with applicable data protection laws. Please contact us for further
                        information.
                    </p>
                    <h2>7. DATA SECURITY</h2>
                    <p>
                        We have put in place appropriate technical and organisational security measures to
                        prevent accidental or intentional manipulation, partial or complete loss or
                        destruction of, or unauthorized access to personal data by third parties.
                    </p>
                    <p>
                        We limit access to your personal data to the employees, agents, contractors and other
                        third parties who have a business need.
                    </p>
                    <p>
                        We have implemented procedures to deal with any suspected personal data breach. We
                        will notify you and any applicable regulator of a breach where we are legally required
                        to do so.
                    </p>
                    <h2>8. DATA RETENTION</h2>
                    <p>
                        We will only retain your personal data for as long as reasonably necessary to fulfil
                        the purposes for which it was collected, including for the purposes of satisfying any
                        legal, regulatory, tax, accounting or reporting requirements. We may retain your
                        personal data for a longer period in the event of a complaint or if we reasonably
                        believe there is a prospect of litigation in connection with which the personal data
                        would be relevant.
                    </p>
                    <p>
                        In order to determine the appropriate retention period for personal data, we consider
                        the amount, nature and sensitivity of the personal data, the potential risk of harm
                        from unauthorized use or disclosure of your personal data, the purposes for which we
                        process your personal data and whether we can achieve those purposes through other
                        means, and any applicable legal, regulatory, tax, accounting or other requirements.
                    </p>
                    <p>
                        We may anonymise or aggregate your personal data in such a way that it can no longer
                        be associated with you.&nbsp; Where such data is no longer your personal data, we may
                        retain it for a longer period of time.
                    </p>
                    <p>
                        In some circumstances you have the right to request that your personal data is deleted
                        (further information is set out below).
                    </p>
                    <h2>9. YOUR LEGAL RIGHTS</h2>
                    <p>
                        Under certain circumstances, you may have the following rights under data protection
                        laws in relation to your personal data:
                    </p>
                    <ul style={{ paddingBottom: 0 }}>
                        <li>
                            <strong>Access</strong> – the right to request confirmation of whether we process
                            personal data relating to you and, if so, to request a copy of that personal data.
                        </li>
                        <li>
                            <strong>Correction</strong> – the right to request that we rectify or update your
                            personal data that is inaccurate, incomplete or outdated.
                        </li>
                        <li>
                            <strong>Erasure</strong> – the right to request that we erase your personal data
                            in certain circumstances, such as where we collected personal data on the basis of
                            your consent and you withdraw your consent.
                        </li>
                        <li>
                            <strong>Restriction of processing</strong> – the right to request that we restrict
                            the use of your personal data in certain circumstances, such as while we consider
                            another request that you have submitted, for example, a request that we update
                            your personal data.
                        </li>
                        <li>
                            <strong>Withdraw consent</strong> – where our processing of your personal data is
                            based on your consent, the right to withdraw your consent; however withdrawal of
                            consent (i) does not invalidate the consent-based processing that occurred prior
                            to such withdrawal, or processing that is not based on consent, and (ii) means
                            that we will no longer be able to perform such consent-based processing
                            activities, which may result in us being unable to provide certain services to
                            you.
                        </li>
                        <li>
                            <strong>Portability</strong> – the right to request a copy of your personal data
                            that we process in a structured, commonly used and machine readable format and to
                            have that data transmitted to an alternative data controller.
                        </li>
                        <li>
                            <strong>Objection to processing</strong> – the right to object to certain data
                            processing, such as for direct marketing purposes or where we rely on legitimate
                            interests to which you object based on your particular circumstances.
                        </li>
                    </ul>
                    <p>
                        If you wish to exercise any of these rights, please contact us using the information
                        provided in the Contact Details section below.&nbsp; We try to respond to all
                        legitimate requests within one month. It can take us longer than a month to respond if
                        your request is particularly complex or if you make a number of requests. In this
                        case, we will notify you and keep you updated.
                    </p>
                    <h2>10. CONTACT DETAILS AND IMPORTANT INFORMATION</h2>
                    <h3>Contact Information</h3>
                    <p>
                        HappyTravelDotCom Travel and Tourism LLC
                        <br />
                        B106, Saraya Avenue Building
                        <br />
                        P.O. Box 36366
                        <br />
                        Dubai, United Arab Emirates
                        <br />
                        Email:{' '}
                        <a className="dark-link" href="mailto:info@happytravel.com">
                            info@happytravel.com
                        </a>
                        <br />
                        Phone: +971-4-2999080
                    </p>
                    <p>
                        You can also contact us if you have a complaint in connection with our processing of
                        your personal data. We would like the opportunity to address your concerns in the
                        first instance, however, you may also have a right to complain at any time to your
                        relevant data protection supervisory authority.
                    </p>
                    <h3>Changes to the Privacy Notice / Your Personal Data</h3>
                    <p>
                        We regularly review our privacy notice. The most recent update was in December 2022.
                        Please check from time to time for any updates to this notice.
                    </p>
                    <p>
                        To ensure the personal data we hold about you is accurate and current, please inform
                        us if your personal data changes during your relationship with us.
                    </p>
                    <h3>Third Party Links</h3>
                    <p>
                        Our website may include links to third-party websites, plug-ins and applications.
                        HappyTravel does not control such third party websites and is not responsible for
                        their privacy notices. Clicking on these links or allowing these connections may
                        enable third-parties to collect or share your data. When you leave our website, we
                        encourage you to read the privacy notice of each website you visit.
                    </p>

                    <div className="updated">Updated on December 12, 2022</div>

                    <div className="pdf-button-holder">
                        <a
                            href="/documents/happytravel-privacy-policy.pdf"
                            className="button main"
                            target="_blank"
                        >
                            Download Privacy Policy
                        </a>
                    </div>
                </section>
            </div>

            <Consent />
            <Footer />
        </div>
    );
};

export default PrivacyPage;
