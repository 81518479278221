import { makeAutoObservable } from 'mobx';
import autosave from 'htcore/misc/autosave';

const ourCompanyInfoDefault = {
    name: 'HappyTravelDotCom Travel and Tourism LLC',
    address: 'B105, Saraya Avenue building',
    country: 'United Arab Emirates',
    city: 'Dubai',
    phone: '+971-4-2940007',
    email: 'info@happytravel.com',
    postalCode: 'Box 36690',
    trn: '100497287100003',
    tradeLicense: '828719',
};

class UIStore {
    regions = [];
    ourCompanyInfo = ourCompanyInfoDefault;
    formCache = {};
    currentAPIVersion = null;
    itnMode = null;
    itnModeAdditionalData = {};

    constructor() {
        makeAutoObservable(this);
        autosave(this, '_ui_store_cache', (values) => {
            const short = JSON.parse(JSON.stringify(values));
            if (short.formCache.BookingForm) {
                short.formCache.BookingForm = JSON.stringify({
                    ...JSON.parse(short.formCache.BookingForm),
                    surrogateAgencyId: null,
                    surrogateAgencyIdCode: null,
                });
            }
            return short;
        });
    }

    setRegions(value) {
        if (!value || !Array.isArray(value)) return [];

        this.regions = value.sort((currentRegion, nextRegion) => {
            if (currentRegion.id === 142 || currentRegion.name === 'Asia') {
                return -1;
            }
            if (nextRegion.id === 142 || nextRegion.name === 'Asia') {
                return 1;
            }
            return 0;
        });
    }

    getFormCache(formName) {
        if (!formName) return null;
        if (!this.formCache?.[formName]) return null;
        let result = null;
        try {
            result = JSON.parse(this.formCache[formName]);
        } catch (e) {}
        return result;
    }

    setFormCache(formName, values) {
        if (!formName) return null;
        this.formCache[formName] = values ? JSON.stringify(values) : null;
    }

    dropFormCache(formName) {
        this.setFormCache(formName);
    }

    dropAllFormCaches() {
        this.formCache = {};
    }

    setOurCompanyInfo(values) {
        this.ourCompanyInfo = values;
    }
    setCurrentAPIVersion(values) {
        this.currentAPIVersion = values;
    }
    getItnCode() {
        if (!this.itnMode) return '';
        return this.itnMode;
    }
    setIntMode(code, additionalData) {
        this.itnMode = code;
        this.itnModeAdditionalData = additionalData;
    }
    resetItnMode() {
        this.itnMode = null;
        this.itnModeAdditionalData = {};
    }
}

export default new UIStore();
