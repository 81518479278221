import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { GroupRoomTypesAndCount, price, date } from 'simple';
import { copyToClipboard } from 'simple/logic';
import { MealPlan } from 'components/accommodation';
import Deadline from 'components/deadline';
import { DailyRatesContract } from 'components/simple';
import { $accommodation } from 'stores';
import SearchResultMarkup from '../search-results/search-result-markup';
import FinalPriceModalWindow from './final-price-modal-window';

const RoomSummary = observer(({ isAgencySearch, htId, roomContractSet, onSelect, accommodationName }) => {
    const [loadedDeadline, setLoadedDeadline] = useState(null);
    const [isDailyRatesVisible, setDailyRatesVisibility] = useState(null);
    const [openFinalPriceModal, setOpenFinalPriceModal] = useState(false);

    const toggleDailyRates = useCallback(() => setDailyRatesVisibility((v) => !v), [setDailyRatesVisibility]);

    const copy = (e) => {
        e.stopPropagation();

        const deadline = loadedDeadline?.date || roomContractSet.deadline.date;
        let deadlineString = '';

        if (roomContractSet.deadline.isFinal || loadedDeadline) {
            if (!deadline) deadlineString = 'FREE Cancellation - Without Prepayment';
            else if (date.isFuture(deadline)) deadlineString = 'Deadline' + ': ' + date.format.dots(deadline);
            else deadlineString = 'Within deadline';
            deadlineString += ', ';
        }

        copyToClipboard(
            [
                date.format.dots(date.day($accommodation.search.request.checkInDate)) +
                    ' – ' +
                    date.format.dots(date.day($accommodation.search.request.checkOutDate)),
                accommodationName,
                GroupRoomTypesAndCount({ contracts: roomContractSet.rooms, text: true }),
                $accommodation.search.request.adultsTotal +
                    'A' +
                    ($accommodation.search.request.childrenTotal
                        ? ', ' + $accommodation.search.request.childrenTotal + 'C'
                        : ''),
                roomContractSet.rooms[0].mealPlan,
                deadlineString + price(roomContractSet.rate.finalPrice).trim(),
            ].join(', ')
        );
    };

    const onClickFinalPriceButton = () => {
        if (openFinalPriceModal) {
            setOpenFinalPriceModal(false);
            $accommodation.clearRoomContractSet();
        } else {
            onSelect();
            setOpenFinalPriceModal(true);
        }
    };

    const showDailyRates = Boolean(roomContractSet.rooms[0]?.dailyRoomRates?.length);

    const FinalMealPlan = roomContractSet.rooms[0]?.boardBasis ? (
        <MealPlan short room={roomContractSet.rooms[0]} allRooms={roomContractSet.rooms} />
    ) : (
        'Room Only'
    );

    const FinalDeadline = (
        <Deadline
            isAgencySearch={isAgencySearch}
            searchId={$accommodation.search.id}
            htId={htId}
            roomContractSet={roomContractSet}
            loadedDeadline={loadedDeadline}
            setLoadedDeadline={setLoadedDeadline}
            checkInDate={$accommodation.search.request.checkInDate}
        />
    );

    const Tags = (
        <>
            {roomContractSet.supplier && (
                <div className="tag">
                    Supplier:{' '}
                    {roomContractSet.supplier +
                        (roomContractSet.subSupplier ? ` (${roomContractSet.subSupplier})` : '')}
                </div>
            )}
            <div className="tag deadline-tag show-phone">{FinalDeadline}</div>
            <div className="tag show-phone">Meal Plan: {FinalMealPlan}</div>
            {Boolean(roomContractSet.rooms.find((item) => item.isAvailableImmediately === false)) && (
                <div className="tag">
                    <b>On Request</b>
                </div>
            )}
            {roomContractSet.isDirectContract && <div className="tag">Direct Connectivity</div>}
            {roomContractSet.isPackageRate && <div className="tag">Package Rate</div>}
            {roomContractSet.rooms[0]?.isDynamic === true && <div className="tag">Dynamic Offer</div>}
        </>
    );

    const onSelectFunc = isAgencySearch ? () => {} : onSelect;

    return (
        <>
            <div className="room">
                <div className="room-details" data-is-agency-search={isAgencySearch}>
                    <div className="info" onClick={onSelectFunc}>
                        <h3 className="copier-wrapper">
                            <div>
                                <GroupRoomTypesAndCount contracts={roomContractSet.rooms} />
                            </div>
                            <span onClick={copy} className="copier" title="Copy to Clipboard">
                                <span className="icon icon-copy" />
                            </span>
                        </h3>
                        <div className="hide-phone">{Tags}</div>
                    </div>
                    <div className="deadline-column" onClick={onSelectFunc}>
                        <div className="label">Deadline</div>
                        {FinalDeadline}
                    </div>
                    <div className="meal-plan" onClick={onSelectFunc}>
                        <div className="label">Meal Plan</div>
                        {FinalMealPlan}
                    </div>
                    <div className="price" onClick={showDailyRates ? toggleDailyRates : onSelectFunc}>
                        {price(roomContractSet.rate.finalPrice)}
                        {roomContractSet.rate.totalMarkupPercent !== null ? (
                            <SearchResultMarkup
                                isAgencySearch={isAgencySearch}
                                appliedMarkups={roomContractSet.rate.appliedMarkups}
                                totalMarkupPercent={roomContractSet.rate.totalMarkupPercent}
                            />
                        ) : null}
                        {showDailyRates ? (
                            <div className="note daily-rates-button-holder">
                                <button>
                                    Show Daily Rates
                                    <span
                                        className={
                                            'icon icon-big-expand' + (isDailyRatesVisible ? ' expanded' : '')
                                        }
                                    />
                                </button>
                            </div>
                        ) : null}
                    </div>
                    {isAgencySearch ? (
                        <div className="action-buttons">
                            <button className="button" onClick={() => onClickFinalPriceButton()}>
                                Final Price
                            </button>
                        </div>
                    ) : (
                        <div className="button-holder">
                            <div className="button-wrapper">
                                <button className="button small main next-button" onClick={onSelectFunc}>
                                    <i className="icon icon-arrow-next" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {isDailyRatesVisible && <DailyRatesContract rooms={roomContractSet.rooms} />}
                <div className="phone-tags">{Tags}</div>
            </div>

            {openFinalPriceModal && <FinalPriceModalWindow onClose={onClickFinalPriceButton} />}
        </>
    );
});

export default RoomSummary;
