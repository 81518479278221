import CachedForm from './cached-form';
import FieldText from './field-text';
import FieldSwitch from './field-switch';
import FieldCheckbox from './field-checkbox';
import FieldTextarea from './field-textarea';
import FieldRange from './field-range';
import FieldSelect from './field-select/field-select';
import FieldSelectWithSearch from './field-select/field-select-with-search';
import FieldDatepicker from './field-datepicker/field-datepicker';
import FieldChildren from './field-children';
import FieldPhone from './field-phone';

const FORM_NAMES = {
    CreateInviteForm: 'CreateInviteForm',
    RegistrationAgentForm: 'RegistrationAgentForm',
    RegistrationCompanyForm: 'RegistrationCompanyForm',
    SearchForm: 'SearchForm',
    BookingForm: 'BookingForm',
    AgenciesSearchForm: 'AgenciesSearchForm',
};

export {
    CachedForm,
    FieldText,
    FieldSwitch,
    FieldCheckbox,
    FieldTextarea,
    FieldRange,
    FieldSelect,
    FieldSelectWithSearch,
    FieldDatepicker,
    FieldChildren,
    FieldPhone,
    FORM_NAMES,
};
