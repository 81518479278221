import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import NavigationBar from 'components/navigation/navigation-bar';
import { $auth } from 'stores';

const formatDataForTabs = (itnData) =>
    itnData
        .map(({ data }) => {
            const isPermitted =
                __devEnv ||
                __localhost ||
                $auth.activeAgency?.agencyId === 3 ||
                $auth.activeAgency?.agencyId === 503;

            const serviceData = data.services.map((service) => ({
                isService: true,
                id: service.id,
                value: service.referenceCode,
                link: `/booking/${service.referenceCode}`,
            }));

            return [
                {
                    value: data.referenceCode,
                    link: `/booking/${data.referenceCode}`,
                    id: data.id,
                },
                ...(isPermitted ? serviceData : []),
            ];
        })
        .flat()
        .sort((a, b) => a.id - b.id);

const createItemsArray = (itnData) => {
    const itnItems = formatDataForTabs(itnData);

    const result = [];

    let serviceIndex = 1;
    let bookingIndex = 1;

    itnItems.forEach(({ isService, ...item }) => {
        const index = isService ? serviceIndex++ : bookingIndex++;

        result.push({
            ...item,
            text: `${isService ? 'Service Booking' : 'Booking'} #${index}`,
        });
    });

    return result;
};

const BookingTabs = ({ itineraryNumber }) => {
    const { code } = useParams();
    const [items, setItems] = useState([]);

    useEffect(() => {
        API.get({
            url: apiMethods.AGENCY_BOOKINGS_LIST,
            body: {
                $filter: `data/itineraryNumber eq '${itineraryNumber}'`,
                $orderBy: 'data/id asc',
            },
            success: (result) => setItems(createItemsArray(result)),
        });
    }, []);

    return <NavigationBar active={code} items={items} />;
};

export default BookingTabs;
