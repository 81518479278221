import React, { useState } from 'react';
import HotelMapModal from './hotel-map-modal';

const HotelOnMapButton = ({ hotel }) => {
    const [modalVisibility, setModalVisibility] = useState(false);

    const { name, rating } = hotel;
    const { latitude, longitude } = hotel.location.coordinates;

    const openMap = (event) => {
        event.stopPropagation();
        setModalVisibility(true);
    };

    return (
        <>
            <button className="button second" onClick={openMap}>
                <i className="icon icon-search-location-on-map" />
                Show On Map
            </button>
            <HotelMapModal
                isOpen={modalVisibility}
                onClose={() => setModalVisibility(false)}
                latitude={latitude}
                longitude={longitude}
                name={name}
                rating={rating}
            />
        </>
    );
};

export default HotelOnMapButton;
