[
    {
        "name": "Caterina",
        "position": "Founder",
        "photo": "/images/landing/about-us/team/caterina.png",
        "description": "After more than two decades of experience in Dubai's travel business, including running one of the most successful luxury transportation companies in the city, Caterina takes the wheel at Happy Travel & Tourism as it moves towards becoming a leading global wholesaler."
    },
    {
        "name": "Taqi Ahmed",
        "position": "Business Partner",
        "photo": "/images/landing/about-us/team/taqi.png",
        "description": "Born and raised in Dubai, Taqi is a natural people person who enjoys learning about the cultures, foods, and travel adventures of people from around the world. Being part of a professional team and a global company like Happy Travel and Tourism is a dream come true for Taqi."
    },
    {
        "name": "Dennis",
        "position": "Chief Operating Officer",
        "photo": "/images/landing/about-us/team/dennis.png",
        "description": "Dennis has been involved in the travel and hospitality industry for over 20 years. Having started his career in retail agency management, Dennis moved on to a leading global wholesaler in which he held leadership roles in product development, destination management and business development."
    },
    {
        "name": "Rosemary",
        "position": "Business Partner",
        "photo": "/images/landing/about-us/team/rosemary.png",
        "description": "Over a decade of travel trade experience in the U.K. and nearly three decades in the Industry in the Middle East. An executive position with a global wholesaler, a position as Director Middle East for a major cruise line, as well as a director in a global representation company, round out her experience."
    },
    {
        "name": "Thushara Devi",
        "position": "Accounting Manager",
        "photo": "/images/landing/about-us/team/thushara.png",
        "description": "Bringing over a decade of experience in accounting, with half of that time immersed in the travel industry, Thushara brings her expertise in financial management, corporate financial planning and analysis, and risk identification strategies. Thushara is a proactive advocate of the Company's vision, aligning financial initiatives with overarching goals and contributing to the sustained success and growths."
    },
    {
        "name": "Bryan",
        "position": "Head of Operations",
        "photo": "/images/landing/about-us/team/bryan.png",
        "description": "A dynamic member of the first startup team when the Company opened, with 26 years of experience in the travel industry, a deep passion for the travel business and a desire to constantly take on new challenges."
    },
    {
        "name": "Ibtihal Al Hosni",
        "position": "Regional Business Development Manager – Middle East",
        "photo": "/images/landing/about-us/team/ibtihal.png",
        "description": "A seasoned professional with over 17 years of expertise in selling B2B online booking portals across the dynamic landscape of the Middle East. With a robust background in the travel trade, Ibti has cultivated extensive relationships and garnered invaluable experience with leading wholesalers and has established a formidable presence in the UAE and Oman."
    },
    {
        "name": "Rashid Al Chaudhry",
        "position": "Regional Business Development Manager – Middle East",
        "photo": "/images/landing/about-us/team/rashid.png",
        "description": "With a solid 16-year tenure in the travel trade industry, Rashid has been at the forefront of key sales initiatives with leading wholesalers. His consistent track record of delivering results speaks volumes about his prowess in the field. Rashid's strategic acumen has been pivotal in driving business growth by adeptly employing customer acquisition and retention strategies."
    },
    {
        "name": "Juliet Thomas",
        "position": "Reservations Sales Supervisor",
        "photo": "/images/landing/about-us/team/juliet.png",
        "description": "Juliet brings a wealth of product expertise, supported by years of versatile experience in the industry. Her professional journey encompasses various crucial aspects of the industry, including contracting, integrations, connectivity and system support."
    }
]
