import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { remapStatusExtendedWithRooms } from 'simple';
import { Dual, Loader } from 'components/simple';
import ViewFailed from 'common/misc/view-failed';
import BookingActionPart from './booking-actions';
import BookingDetailsView from './booking-details-view';
import BookingSummary from '../parts/booking-summary';
import BookingHistory from '../parts/booking-history';
import CancellationConfirmationModal from './cancellation-confirmation-modal';
import BookingHotelConfirmationCode from './booking-hotel-confirmation-code';
import BookingAgencyReferenceCode from './booking-agency-reference-code';
import BookingItinerary from './booking-itinerary';
import BookingTabs from './booking-tabs';
import BookingSpecialRequest from './booking-special-request';
import { $payment, $auth } from 'stores';

const MAXIMUM_BOOKINGS_COUNT = 15;

const BookingConfirmationView = observer(({ referenceCode, serviceCode, PaymentInformation }) => {
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState(null);
    const [isRoomCancellationAvailable, setRoomCancellationAvailability] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(null);
    const navigate = useNavigate();

    const isApiClient = $auth.activeAgency?.isApiClient;

    const loadBooking = async () => {
        setLoading(true);
        const result = await API.get({
            url: apiMethods.BOOKING_GET_BY_CODE(serviceCode || referenceCode, !!serviceCode),
            after: () => setLoading(false),
        });
        result.bookingDetails.roomDetails = [
            ...result.bookingDetails.roomDetails.filter((room) => !room.cancelled),
            ...result.bookingDetails.roomDetails.filter((room) => room.cancelled),
        ];
        setRoomCancellationAvailability(
            result.bookingDetails.roomDetails.filter(
                (room) => !room.cancelled && room.supplierRoomReferenceCode
            ).length > 1
        );
        result.bookingDetails.roomDetails.forEach((room, index) => (room._index = index + 1));

        setBooking(result);
        setLoading(false);
    };

    const updateBookingStatus = async () => {
        setLoading(true);
        try {
            await API.post({
                url: apiMethods.BOOKING_STATUS(booking.bookingId),
            });
            loadBooking();
        } catch {
            setLoading(false);
        }
    };

    const payNowByCard = () => {
        $payment.setSubject(
            booking.bookingDetails.referenceCode,
            booking.creditCardPrice || booking.totalPrice,
            booking.paymentMethod
        );
        navigate('/payment/form');
    };

    useEffect(() => {
        setBooking(null);
        loadBooking();
    }, [referenceCode, serviceCode]);

    useEffect(() => {
        return () => {
            $payment.clearPaymentResult();
        };
    }, []);

    if (!booking && loading) return <Loader />;

    if (!booking)
        return (
            <ViewFailed
                reason="Unable to load a booking confirmation"
                button="Back to Booking Management"
                link="/bookings"
            />
        );

    const details = booking.bookingDetails;

    return (
        <>
            {loading && <Loader page />}

            <div className="itinerary-wrapper">
                <BookingItinerary
                    itineraryNumber={booking.itineraryNumber}
                    isContinuePossible={
                        details.status === 'Confirmed' &&
                        ($auth.activeAgency?.agencyId === booking.agentInformation?.agencyId ||
                            $auth.information?.id === booking.surrogateAgentId) &&
                        booking.itineraryBookingsCount < MAXIMUM_BOOKINGS_COUNT
                    }
                    bookingCurrency={booking.totalPrice.currency}
                    surrogateAgencyName={booking.surrogateAgencyName}
                    surrogateAgencyId={booking.surrogateAgencyId}
                    surrogateAgentName={booking.surrogateAgentName}
                    surrogateAgentId={booking.surrogateAgentId}
                    isCurrentAgent={$auth.information?.id === booking.surrogateAgentId}
                />

                {booking.itineraryBookingsCount > 1 && (
                    <div className="tabs-wrapper">
                        <BookingTabs itineraryNumber={booking.itineraryNumber} />
                    </div>
                )}
            </div>

            <div className="billet-wrapper">
                <div className="billet">
                    <BookingSummary
                        isService={!!serviceCode}
                        details={booking.bookingDetails}
                        contract={booking}
                        checkInDate={details.checkInDate}
                        checkOutDate={details.checkOutDate}
                        fromHistory
                    />
                    {booking.paymentStatus && (
                        <Dual
                            a="Payment Status"
                            b={<strong>{booking.paymentStatus.replace(/([A-Z])/g, ' $1')}</strong>}
                        />
                    )}

                    <BookingActionPart
                        isService={!!serviceCode}
                        isApiClient={isApiClient}
                        booking={booking}
                        showCancellationConfirmation={() => setModalVisibility(true)}
                        payNowByCard={payNowByCard}
                        isRoomCancellationAvailable={isRoomCancellationAvailable}
                    />
                </div>
                <div className="another">
                    {PaymentInformation ? PaymentInformation : null}
                    <div className="accent-frame">
                        <div className={'before' + __class(details.status)}>
                            {details.status === 'Confirmed' || details.status === 'Completed' ? (
                                <span className="icon icon-success" />
                            ) : (
                                <span className="icon icon-information" />
                            )}
                        </div>
                        <div className="data">
                            <div className="first">
                                <div className="title">Booking Reference Number</div>
                                <span className={'status ' + details.status}>{details.referenceCode}</span>
                                <BookingHotelConfirmationCode
                                    serviceCode={serviceCode}
                                    loadBooking={loadBooking}
                                    bookingDetails={details}
                                />
                                <BookingAgencyReferenceCode
                                    loadBooking={loadBooking}
                                    bookingDetails={details}
                                    booking={booking}
                                    isFirstLoad={Boolean(PaymentInformation)}
                                />
                            </div>
                            <div className="second">
                                <div className="title">Status</div>
                                <strong
                                    className={
                                        'status ' +
                                        remapStatusExtendedWithRooms(
                                            details.status,
                                            details.roomDetails
                                        ).replace(/\s/g, '')
                                    }
                                >
                                    {remapStatusExtendedWithRooms(details.status, details.roomDetails)}
                                </strong>
                            </div>
                        </div>
                        <div className="after">
                            {$auth.permitted('ModifyBooking') &&
                                !serviceCode &&
                                ![
                                    'ManualCorrectionNeeded',
                                    'Cancelled',
                                    'Rejected',
                                    'Discarded',
                                    'Confirmed',
                                    'Completed',
                                ].includes(details.status) &&
                                !isApiClient && (
                                    <div className="status-updater">
                                        {loading ? (
                                            <span>...</span>
                                        ) : (
                                            <button className="small button" onClick={updateBookingStatus}>
                                                <span className="icon icon-refresh" />
                                            </button>
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                    <BookingSpecialRequest
                        isService={!!serviceCode}
                        booking={booking}
                        loadBooking={loadBooking}
                    />
                    <BookingDetailsView isService={!!serviceCode} booking={booking} />
                    <BookingHistory isService={!!serviceCode} booking={booking} />
                </div>
            </div>
            {$auth.permitted('ModifyBooking') && modalVisibility && (
                <CancellationConfirmationModal
                    isOpen
                    onClose={() => setModalVisibility(false)}
                    onCancel={loadBooking}
                    booking={booking}
                    isRoomCancellationAvailable={isRoomCancellationAvailable}
                    totalPrice={booking.totalPrice}
                />
            )}
        </>
    );
});

export default BookingConfirmationView;
