import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { CachedForm, FieldText } from 'components/form';
import { Loader } from 'components/simple';
import { observer } from 'mobx-react';
import ModalTemplate from 'common/template/modal-template';
import { $notifications } from 'stores';

const BookingAgencyReferenceCodeModal = observer((props) => {
    const { bookingDetails, booking, onSuccess, onClose } = props;

    const [loading, setLoading] = useState(false);

    const submit = (values) => {
        setLoading(true);
        API.put({
            url: apiMethods.BOOKING_AGENCY_REFERENCE_CODE(bookingDetails.referenceCode),
            body: {
                agencyReferenceCode: values?.agencyReferenceCode || '',
            },
            success: () => {
                if (values.agencyReferenceCode !== booking.agencyReferenceCode) {
                    $notifications.addNotification(
                        `You have changed the Agency Reference Code. To reflect this change in booking documents, please download an updated invoice and voucher.`,
                        undefined,
                        'success'
                    );
                }
                onSuccess();
            },
            after: () => setLoading(false),
        });
    };

    if (!props.isOpen) return null;

    return (
        <ModalTemplate addClass="wide" {...props}>
            <h2 style={{ textAlign: 'center' }}>
                {booking.agencyReferenceCode && booking.agencyReferenceCode !== bookingDetails.referenceCode
                    ? 'Update Agency Reference Code'
                    : 'Set Agency Reference Code'}
            </h2>

            {loading && <Loader page />}

            <CachedForm
                initialValues={{ agencyReferenceCode: booking.agencyReferenceCode }}
                onSubmit={submit}
                render={(formik) => (
                    <div className="form" style={{ marginTop: 50 }}>
                        <div style={{ marginBottom: 20, marginRight: 30, fontSize: '14px' }}>
                            Please, if available, enter the Agency Reference Code
                        </div>
                        <div>
                            <FieldText
                                formik={formik}
                                id="agencyReferenceCode"
                                label="Agency Reference Code"
                                placeholder="Any Agency Reference Code"
                                maxLength={25}
                            />
                        </div>
                        <div
                            className="bottom"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                className="button"
                                type="button"
                                onClick={onClose}
                                style={{ maxWidth: '120px' }}
                            >
                                Cancel
                            </button>
                            <button className="button main" type="submit" style={{ maxWidth: '120px' }}>
                                Set
                            </button>
                        </div>
                    </div>
                )}
            />
        </ModalTemplate>
    );
});

export default BookingAgencyReferenceCodeModal;
