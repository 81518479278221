import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Popup } from 'react-leaflet';
import { price } from 'simple';
import { HotelStars } from 'components/accommodation';
import { searchRoomsCreate } from 'tasks/accommodation/search-rooms-loaders';

const MapMarkerPopup = ({ item }) => {
    const navigate = useNavigate();

    const processSearch = () => {
        searchRoomsCreate(
            { htId: item.accommodationHtId, accommodation: { htId: item.accommodationHtId } },
            navigate,
            false,
            true
        );
    };

    return (
        <Popup>
            <div className="marker-popup">
                <div className="inner">
                    <div className="hotel-name">{item.accommodationName}</div>
                    <div className="rating">
                        <HotelStars count={item.rating} />
                    </div>
                    <div className="price">
                        <div className="price-from">From</div>
                        <div className="amount">{price(item.minPrice)}</div>
                    </div>
                </div>
                <button className="button main" type="button" onClick={processSearch}>
                    Book Now
                </button>
            </div>
        </Popup>
    );
};

export default MapMarkerPopup;
