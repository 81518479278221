import React from 'react';
import { Popup } from 'react-leaflet';
import { HotelStars } from 'components/accommodation';

const HotelMapMarkerPopup = ({ name, rating, onClose }) => {
    return (
        <Popup>
            <div className="marker-popup">
                <div className="inner">
                    <div className="hotel-name">{name}</div>
                    <div className="rating">
                        <HotelStars count={rating} />
                    </div>
                </div>
                <button className="button main" type="button" onClick={onClose}>
                    Back To Rooms
                </button>
            </div>
        </Popup>
    );
};

export default HotelMapMarkerPopup;
