import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import RoomSummary from '../parts/room-summary';
import { $accommodation } from 'stores';
import { price } from 'simple';

const RoomContractSetGroup = observer(
    ({ isAgencySearch, expandedGroups, setExpandedGroups, group, groupsCount, roomContractSetSelect }) => {
        const selectedAccommodation = $accommodation.selected.accommodation;

        const isExpanded = expandedGroups?.includes(group?.index);

        const toggle = () => {
            setExpandedGroups((prevState) => {
                if (prevState.includes(group.index)) {
                    return prevState.filter((item) => item !== group.index);
                }
                return [...prevState, group.index];
            });
        };

        const lowestPrice = useMemo(() => {
            if (!group.roomContractSets) return null;

            return group?.roomContractSets.reduce(
                (minRoom, room) => {
                    const roomPrice = room.rate.finalPrice.amount;
                    return roomPrice < minRoom.amount
                        ? { amount: roomPrice, currency: room.rate.finalPrice.currency }
                        : minRoom;
                },
                { amount: Infinity, currency: '' }
            );
        }, [group?.roomContractSets]);

        return (
            <div className="group">
                {groupsCount > 1 ? (
                    <div className="name" onClick={toggle}>
                        <span>
                            {group.groupName || 'Other Room Categories'} (
                            {group.roomContractSets?.length || 0})
                        </span>

                        {lowestPrice && <span className="min-price">from {price(lowestPrice)}</span>}
                        <span className={'icon icon-big-expand' + (isExpanded ? ' expanded' : '')} />
                    </div>
                ) : null}
                {isExpanded
                    ? group.roomContractSets?.map((roomContractSet, index) => (
                          <RoomSummary
                              key={index}
                              isAgencySearch={isAgencySearch}
                              roomContractSet={roomContractSet}
                              onSelect={() => roomContractSetSelect(roomContractSet)}
                              htId={selectedAccommodation.htId}
                              accommodationName={selectedAccommodation.name}
                          />
                      ))
                    : null}
            </div>
        );
    }
);

export default RoomContractSetGroup;
