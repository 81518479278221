import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import BookingAgencyReferenceCodeModal from './booking-agency-reference-code-modal';
import { $auth } from 'stores';

const BookingAgencyReferenceCode = observer(({ loadBooking, bookingDetails, booking, isFirstLoad }) => {
    const [isEditorModalOpen, setEditorModalOpen] = useState(false);
    const { agencyReferenceCode = '' } = booking;
    const { referenceCode } = bookingDetails;

    const isHTAgent = $auth.activeAgency?.canUseSurrogateAgency || $auth.activeAgency?.agencyId === 503;

    const isButtonVisible =
        $auth.permitted('ModifyBooking') &&
        $auth.permitted('AgencyCodeManagement') &&
        !isHTAgent &&
        !['ManualCorrectionNeeded', 'Rejected', 'Discarded', 'Completed'].includes(bookingDetails.status);

    useEffect(() => {
        if (isButtonVisible && isFirstLoad && agencyReferenceCode === null) {
            setEditorModalOpen(true);
        }
    }, [isButtonVisible, isFirstLoad, agencyReferenceCode]);

    return (
        <>
            <BookingAgencyReferenceCodeModal
                bookingDetails={bookingDetails}
                booking={booking}
                isOpen={isEditorModalOpen}
                onClose={() => setEditorModalOpen(false)}
                onSuccess={() => {
                    loadBooking();
                    setEditorModalOpen(false);
                }}
                key={referenceCode}
            />
            {!agencyReferenceCode || agencyReferenceCode === referenceCode ? (
                <>
                    {isButtonVisible ? (
                        <div className="additional-reference" style={{ marginTop: '10px' }}>
                            <button className="button small" onClick={() => setEditorModalOpen(true)}>
                                Set Agency Reference Code
                            </button>
                        </div>
                    ) : null}
                </>
            ) : (
                <div className="additional-reference">
                    <div className="title">Agency Reference Code</div>
                    <div>
                        <span className="status">{agencyReferenceCode}</span>
                    </div>

                    {isButtonVisible && (
                        <button
                            className="button small"
                            onClick={() => setEditorModalOpen(true)}
                            style={{ marginTop: 10 }}
                        >
                            Change Agency Reference Code
                        </button>
                    )}
                </div>
            )}
        </>
    );
});

export default BookingAgencyReferenceCode;
