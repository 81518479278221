import React from 'react';
import { useTitle } from 'htcore';
import Header from '../components/header';
import Footer from '../components/footer';
import Consent from '../components/consent';
import useGoogleAnalytics from '../hooks/use-google-analytics';

const TermsPage = () => {
    useTitle('Terms & Conditions');
    useGoogleAnalytics();

    return (
        <div className="lg">
            <div className="container">
                <Header />
                <section className="section-wrapper document block">
                    <h1>Terms & Conditions</h1>

                    <p>
                        In these terms of use (“<strong>Terms of Use</strong>”) for the web site (“
                        <strong>Site</strong>”) of HAPPYTRAVELDOTCOM Travel &amp; Tourism LLC (“
                        <strong>Happy Travel</strong>”, “<strong>our</strong>”, “<strong>us</strong>” or “
                        <strong>we</strong>”), the terms “<strong>you</strong>” and “<strong>your</strong>”
                        refer to any travel companies such as travel agents, tour operators, other wholesalers
                        or airlines (“
                        <strong>Travel Business</strong>”) that wish to purchase one or more travel
                        components, including but not limited to, accommodation, transfers, transportation,
                        sightseeing tours, excursions and meals out (collectively referred to as “
                        <strong>Travel Components</strong>”), but not including airline tickets,made through
                        our online reservation system (including via an application programming interface (
                        <strong>“API”</strong> or<strong> “Site API”</strong>)), and/or our Site, and has
                        signed a Credit Line Commercial Agreement or a Credit Card Commercial Agreement with
                        Happy Travel or any other user of the Site.
                    </p>
                    <p>
                        Please read these Terms of Use carefully. By accessing our Site you represent and
                        warrant to Happy Travel that:
                    </p>
                    <ul>
                        <li>
                            You have signed a Credit Line Commercial Agreement or a Credit Card Commercial
                            Agreement with Happy Travel.
                        </li>
                        <li>You have read and understood these Terms of Use.</li>
                        <li>
                            You agree to be bound by these Terms of Use, in relation to your access to the
                            Site.
                        </li>
                        <li>
                            You as the representative for your Travel Business have the authority to enter
                            into these Terms of Use and bind your Travel Business.
                        </li>
                        <li>You will be responsible for all use of the Site by you.</li>
                        <li>
                            You comply with all applicable laws and regulations with regards to: economic
                            sanctions; export controls; import regulations; and trade embargoes (“
                            <strong>Sanctions</strong>”), including those of the European Union and United
                            States.
                        </li>
                        <li>
                            You acknowledge that you are not targeted by Sanctions nor otherwise owned or
                            controlled by or acting on behalf of any person or entity targeted by Sanctions.
                        </li>
                    </ul>

                    <h2>1. Scope of Services</h2>
                    <ol className="roman">
                        <li>
                            Your use of this Site will constitute your acceptance of the Terms of Use. Once
                            accepted, the Terms of Use will constitute a binding agreement between Happy
                            Travel and you governing your use of the Site (“<strong>Agreement</strong>”). If
                            you are not willing to abide by the Terms of Use, do not use this Site. Happy
                            Travel may change the Terms of Use set out below from time to time. By accessing
                            this Site you are accepting that you are bound by the current Terms of Use and so
                            you should check these each time you revisit the Site.
                        </li>
                        <li>
                            In order to gain access to our services, you confirm that you are a bona fide
                            Travel Business. If you cease to comply with the terms, conditions and
                            requirements set out herein for accessing our Site or obtaining our services, you
                            must notify us immediately. In the event you provide Happy Travel with false
                            information, defraud or attempt to defraud Happy Travel, you will lose all
                            privileges to our online reservation system and we reserve all rights against you.
                        </li>
                        <li>
                            Note, we transact only with travel companies for the sale of Travel Components and
                            make no direct contact with the end customer. Happy Travel is not a travel agent,
                            nor is it acting as a travel agent.
                        </li>
                        <li>
                            We reserve the right to suspend or terminate, in our sole discretion, access to
                            the Site at any time, without advance notice, for general maintenance or for any
                            other reasons.
                        </li>
                    </ol>

                    <h2>2. Use of the Site</h2>
                    <ol className="roman">
                        <li>
                            Happy Travel grants you a limited, restricted, personal, non-transferable,
                            non-sublicensable (other than to affiliates or with our express prior written
                            permission), revocable license to access and use the Site only as expressly
                            permitted in these Terms of Use.
                        </li>
                        <li>
                            Except for this limited license, we do not grant you any other rights or license
                            with respect to the Site; any rights or licenses not expressly granted herein are
                            reserved. The content and information on the Site, as well as the software and
                            infrastructure used to provide such content and information, is proprietary to
                            Happy Travel or its suppliers and providers. You undertake to only use the Site to
                            make bona fide and legitimate enquiries or bookings. You undertake that the
                            payment details you provide us with in making a booking are fully correct and that
                            they will contain, at all times, sufficient funds to cover any amounts due to us.
                            You also undertake to provide correct and accurate e-mail, postal and/or other
                            contact details to Happy Travel and acknowledge that Happy Travel may, in
                            accordance with the terms of our privacy policy found at{' '}
                            <a href="https://happytravel.com/privacy" className="link">
                                https://happytravel.com/privacy
                            </a>
                            , use these details to contact you in the event that this should prove necessary.
                        </li>
                        <li>
                            Happy Travel may use third party technology on its Site to keep the Site safe from
                            malicious attackers or anyone who abuses the Site.
                        </li>
                        <li>
                            We may provide hypertext links to other sites which are operated by other people.
                            We make no endorsement of, accept no responsibility for the content of, and accept
                            no liability for the consequences to you of using, such external sites, which may
                            have their own terms of use to which you will be subject. By clicking on such a
                            link, you acknowledge leaving the Site and proceeding at your own responsibility.
                        </li>
                        <li>
                            In addition, you agree not to:
                            <ul className="dash">
                                <li>
                                    use the Site or its contents for any non-authorized commercial purpose;
                                </li>
                                <li>
                                    use any feature of the Site for any purpose that is unlawful, harmful, or
                                    otherwise objectionable or inappropriate, as determined by us;
                                </li>
                                <li>
                                    use the Site in any way that breaches any applicable local, national or
                                    international law or regulation;
                                </li>
                                <li>make any speculative, false, or fraudulent booking;</li>
                                <li>
                                    access, monitor or copy any content or information of the Site by any
                                    automated means (e.g., “<em>screen scraping</em>”) or any manual process
                                    for any purpose without our express prior written permission;
                                </li>
                                <li>
                                    “<em>deep link</em>” to any portion of the Site for any purpose;
                                </li>
                                <li>
                                    take any action that imposes, or may impose, in our discretion, an
                                    unreasonable or disproportionately large load on our computer or network
                                    equipment;
                                </li>
                                <li>
                                    re-sell, use, copy, monitor, display, download or reproduce any content or
                                    information, software, products or services available on the Site for any
                                    commercial activity or purpose;
                                </li>
                                <li>
                                    incorporate any part of the Site into any other website without our prior
                                    written permission;
                                </li>
                                <li>
                                    deliver, or provide links to, any postings containing material that could
                                    be considered harmful, obscene, pornographic, indecent, lewd, violent,
                                    abusive, profane, racist, discriminating, insulting, threatening,
                                    tortuous, harassing, hateful or otherwise objectionable;
                                </li>
                                <li>
                                    deliver, or provide links to, any postings containing defamatory,
                                    slanderous, false or libelous material;
                                </li>
                                <li>
                                    impersonate another person or entity (including us) or falsely state or
                                    otherwise misrepresent your affiliation with a person or entity, or adopt
                                    a false identity if the purpose of doing so is to mislead, deceive, or
                                    defraud another;
                                </li>
                                <li>use the Site to collect, store or process personal data about others;</li>
                                <li>
                                    use the Site to transmit, or procure the sending of, any unsolicited or
                                    unauthorized advertising or promotional material or any other form of
                                    similar solicitation (<em>i.e.,</em> spam);
                                </li>
                                <li>
                                    use the Site in any manner which could damage, disable, overburden, or
                                    impair or otherwise interfere with the use of the Site or other users’
                                    computer equipment, or cause damage, disruption or limit the functioning
                                    of any software, hardware, or telecommunications equipment;
                                </li>
                                <li>
                                    knowingly transmit any data, send or upload any material that contains
                                    viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware,
                                    adware or any other harmful programs or similar computer code designed to
                                    affect adversely the operation of any computer software or hardware;
                                </li>
                                <li>
                                    attempt to gain unauthorized access to the Site, any related website,
                                    other accounts, computer system, or networks connected to the Site,
                                    through hacking, password mining, or any other means;
                                </li>
                                <li>
                                    obtain or attempt to obtain any materials or information through any means
                                    not intentionally made available through the Site, including harvesting or
                                    otherwise collecting information about others such as email addresses;
                                </li>
                                <li>
                                    do anything else which could cause damage to the Site, Happy Travel, Happy
                                    Travel’s employees and reputations, or would otherwise have a negative
                                    impact on Happy Travel; or
                                </li>
                                <li>aid or abet any of the above.</li>
                            </ul>
                        </li>
                        <li>
                            Please report any abusive content that you see on the Site to us at the following
                            address:{' '}
                            <a href="mailto:support@happytravel.com" className="link">
                                support@happytravel.com
                            </a>
                        </li>
                    </ol>

                    <h2>3. Your Information</h2>
                    <ol className="roman">
                        <li>
                            All information that you provide to us must be accurate and up-to-date. If any of
                            your information changes, you must immediately update it. If you have reason to
                            believe that your login ID, password, or company access code is no longer secure,
                            you must promptly change your information that is affected. You represent and
                            warrant that you are not transmitting or sharing information that you do not have
                            permission to share.
                        </li>
                        <li>
                            If you decide to have messages or other communications from the Site sent directly
                            to your mobile device, you are solely responsible for keeping us updated with your
                            current phone number, and for any charges incurred by receiving such messages. We
                            will not be liable for information sent to a device that is associated with your
                            outdated mobile phone number. If you install any software or enable any service
                            that stores information from the Site on any mobile device or computer, it is your
                            responsibility, prior to transfer or disposal of such device, to remove your
                            information or otherwise disable access to such software or service in order to
                            prevent unauthorized access to your information or account.
                        </li>
                    </ol>

                    <h2>4. Personal Data and Data Privacy</h2>
                    <p className="indent">
                        Happy Travel respects your privacy. Please consult our privacy policy for further
                        information about how we use your personal data{' '}
                        <a href="https://happytravel.com/privacy" className="link">
                            https://happytravel.com/privacy
                        </a>
                        .
                    </p>

                    <h2>5. Pricing</h2>
                    <p className="indent">
                        Prices are confirmed only at the final stage of the booking process, depending on the
                        type of product purchased.
                    </p>

                    <h2>6. Booking Confirmation</h2>
                    <p className="indent">
                        Once you complete your booking, you will receive an email acknowledging the details of
                        your booking and a confirmation number. The completion of your booking will depend on
                        the terms of the individual signed mutual agreement.
                    </p>
                    <p className="indent">
                        We reserve the right to cancel the booking if the booking does not comply with the
                        payment terms agreed as per the signed mutual agreement.
                    </p>

                    <h2>7. Payment policy</h2>
                    <p className="indent">
                        Payment can be made by the payment methods listed on the individual signed mutual
                        agreement.
                    </p>
                    <p className="indent">
                        We accept bank transfers, cash and credit card payments which are Visa, MasterCard,
                        American Express.
                    </p>

                    <h2>8. Cancellation policy</h2>
                    <p className="indent">
                        By making a reservation with an accommodation provider, you accept and agree to the
                        relevant cancellation and no-show policy.
                    </p>
                    <p className="indent">
                        You may be charged for your cancellation according to booking conditions, (pre)payment
                        and no-show policy or not be entitled to any repayment of any (pre)paid amount. The
                        cancellation policy for each booking may be different and will be outlined on your
                        booking confirmation.
                    </p>

                    <h2>9. Refund policy</h2>
                    <p className="indent">
                        Refund requests must be received in writing and processed only if it complies with the
                        terms and conditions of the booking. Any refund request that does not fall under the
                        requisite terms and conditions of the booking will be denied.
                    </p>
                    <p className="indent">
                        Refund requests require a minimum of twenty-one (21) days for processing. No claims
                        will be reviewed once a refund is processed.
                    </p>

                    <h2>10. Delivery policy</h2>
                    <p className="indent">Not Applicable.</p>

                    <h2>11. Intellectual Property</h2>
                    <ol className="roman">
                        <li>
                            “Intellectual Property” refers to intellectual property of every sort, whether or
                            not registered, registrable or applied for in any country, including intellectual
                            property coming into existence in the future; and including, among others, all
                            copyrights in text, images, video, sound or software, trademarks, trade names,
                            service marks, business names, brand names, logos, internet domain names, social
                            media handles, designs, database rights, rights in confidential information and
                            all other intellectual property rights or similar proprietary rights of whatever
                            nature arising from, contained in, or related to material contained on the Site.
                        </li>
                        <li>
                            Unless stated otherwise, Happy Travel owns or has the right to use, and shall
                            retain all such ownership or rights, title and interest in and to, all
                            Intellectual Property rights and published materials, including the look and feel
                            and the infrastructure of the Site.
                        </li>
                        <li>
                            Your use of and access to the Site does not grant you any license or right to use
                            any of the Intellectual Property included on, contained in or related to the Site.
                        </li>
                        <li>
                            You shall indemnify and keep indemnified Happy Travel against any and all claims,
                            costs, demands, actions or proceedings made against or incurred by Happy Travel as
                            a result of your use of Happy Travel’s Intellectual Property not in accordance
                            with these Terms of Use.
                        </li>
                        <li>
                            All copyright, trademark and other proprietary rights notices presented on the
                            Site must appear on all copies that you print from the Site.
                        </li>
                        <li>
                            Happy Travel does not assume any liability for copyrighted materials provided by
                            third parties or any Intellectual Property right infringements by such third
                            parties.
                        </li>
                        <li>
                            When you upload or post content to our Site, you grant us, our affiliates,
                            partners and advertisers a perpetual, irrevocable, unconditional, worldwide,
                            non-exclusive, royalty-free, sublicensable, transferable licence to use,
                            reproduce, distribute, communicate, make available, prepare derivative works from,
                            display and perform that user-generated content (and any Intellectual Property
                            rights contained therein) in connection with the services provided by us and the
                            Site and across different media as well as to promote the Site and our services.
                        </li>
                        <li>
                            By uploading or posting content to our Site, you warrant and agree that (i) you
                            own all Intellectual Property rights (including copyright) in and to the content
                            and (ii) the content does not infringe or otherwise violate any third party’s
                            Intellectual Property rights. You shall indemnify and keep indemnified Happy
                            Travel against any and all claims, costs, demands, actions or proceedings made
                            against or incurred by Happy Travel that may arise in relation to any content you
                            upload or post to our Site.
                        </li>
                    </ol>

                    <h2>12. Warranty Disclaimer</h2>
                    <p className="indent">
                        This Site, all content and services provided on the Site, are provided “as is”, “as
                        available” and without warranties of any kind, whether express or implied, to the
                        fullest extent permissible pursuant to applicable law. Happy Travel and its licensors
                        expressly disclaim to the fullest extent possible all warranties of any kind, whether
                        express or implied, including, but not limited to, any implied warranties of
                        merchantability, fitness for a particular purpose, title, non-infringement, and
                        security and accuracy, as well as all warranties arising by usage of trade, course of
                        dealing, or course of performance. Happy Travel does not make any warranties or
                        representations regarding the use of contents of this Site or Services in terms of
                        their correctness, accuracy, adequacy, usefulness, timeliness, reliability or
                        otherwise. Some jurisdictions do not allow exclusion of implied warranties, so the
                        above limitations and exclusions may not apply to you. Happy Travel does not represent
                        or warrant that the functions or services contained in the Site will be uninterrupted
                        or error-free, that defects will be corrected, or that this Site or the server that
                        makes the Site available are free of viruses or other harmful components. Happy Travel
                        accepts no liability for viruses that may be faced while using the Site and for harm
                        of any kind that may be suffered from such viruses. Please take all appropriate
                        safeguards before downloading information from the Site. Unless Happy Travel is at
                        fault for intentional or willful misconduct, or gross negligence, Happy Travel is not
                        responsible for any failures caused by server errors, misdirected or redirected
                        transmissions, failed internet connections, interruptions in the transmission or
                        receipt of reservations or, any other technical defect whether human or technical in
                        nature.
                    </p>

                    <h2>13. Limitation of Liability and Indemnification</h2>
                    <ol className="roman">
                        <li>
                            Subject to clause 13(ii) below, Happy Travel and its officers, directors,
                            managers, employees, representatives, agents, subsidiaries, affiliates, licensors
                            or others involved in creating, sponsoring, promoting, or otherwise making
                            available the Site and its contents shall not be liable to you or any other party
                            for any direct, indirect, incidental, special, exemplary, compensatory, punitive
                            or consequential damages or any damages or losses whatsoever that result from the
                            use of, or the inability to use, the Site or any of the contents on this Site or
                            the performance of the Site, even if Happy Travel has been advised of the
                            possibility of such damages. Applicable law may not allow the limitation or
                            exclusion of liability or incidental or consequential damages, so the above
                            limitation or exclusion may not apply to you. If you are dissatisfied with any
                            portion of the Site or with any of these Terms of Use, your sole and exclusive
                            remedy is to discontinue using the Site.
                        </li>
                        <li>
                            Nothing in these Terms of Use limits any liability which cannot legally be
                            limited, including that nothing herein shall limit liability for (a) death or
                            personal injury caused by negligence; or (b) fraud or fraudulent
                            misrepresentation.
                        </li>
                        <li>
                            You agree to indemnify, defend and hold harmless Happy Travel, its subsidiaries
                            and other affiliates and their respective officers, directors, managers,
                            employees, representatives, agents, partners, licensors, payment service providers
                            and other applicable third parties from and against any liability, damage or loss,
                            including legal fees and expenses that are incurred or suffered as a result of (i)
                            any breach or non-compliance by you of any law or any term of these Terms of Use
                            (ii) any action, inaction, or omission by you, or (iii) any dispute or litigation
                            caused by your actions, inactions or omission.
                        </li>
                    </ol>

                    <h2>14. Law and Jurisdiction</h2>
                    <ol className="roman">
                        <li>
                            These Terms of Use and any dispute and/or claim arising out of or in connection
                            with them, or their subject matter or formation (including non-contractual
                            disputes or claims) shall be governed by and construed in accordance with the laws
                            of England and Wales.
                        </li>
                        <li>
                            Any claim or dispute arising from or related to, these Terms of Use shall be
                            settled exclusively by arbitration, in the English language, administered by the
                            DIFC-LCIA Arbitration Centre in accordance with the DIFC-LCIA Arbitration Rules
                            then in effect, except as they may be modified by agreement of the parties to the
                            arbitration. Happy Travel has the right to bring proceedings against you for
                            breach of our Terms of Use in your country of residence or any other country.
                        </li>
                    </ol>

                    <div className="updated">Updated on May 05, 2021</div>

                    <div className="pdf-button-holder">
                        <a href="/documents/happytravel-terms.pdf" className="button main" target="_blank">
                            Download Terms & Conditions
                        </a>
                    </div>
                </section>
            </div>

            <Consent />
            <Footer />
        </div>
    );
};

export default TermsPage;
