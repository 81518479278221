const settings = {
    identityUrl: process.env.IDENTITY_URL,
    identityScope: 'edo openid email predictions nagoya offline_access',
    identityClientId: 'matsumoto',
    api: process.env.EDO_URL + 'en/api/1.0',
    osaka: process.env.OSAKA_URL + 'api/1.0',
    pageTitleSuffix: 'Happytravel.com',
    sentry_dsn: process.env.SENTRY_DSN,
    sentry_environment: process.env.SENTRY_ENVIRONMENT,
    build: process.env.BUILD_VERSION || 'JS_Default',
    edo: process.env.EDO_URL,
};

export default settings;
