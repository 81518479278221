import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { countPassengers } from 'simple/logic';
import { SEARCH_STATUSES } from 'enum';
import { searchFormFormatter } from './search-form-formatter';
import { $accommodation, $notifications } from 'stores';

export const searchSaveForm = (values, navigate, fullsize, isAgencySearch) => {
    $notifications.closeAllNotifications();

    const body = searchFormFormatter(values, isAgencySearch);

    if (!fullsize && !isAgencySearch && $accommodation.search.request?.surrogateAgencyId) {
        body.surrogateAgencyId = $accommodation.search.request.surrogateAgencyId;
        body.surrogateAgencyName = $accommodation.search.request.surrogateAgencyName;
        body.surrogateAgentId = $accommodation.search.request.surrogateAgentId;
        body.surrogateAgentName = $accommodation.search.request.surrogateAgentName;
        body.currency = $accommodation.search.request.currency;
        body.surrogateActiveAgencyContractKind =
            $accommodation.search.request.surrogateActiveAgencyContractKind;
    }

    $accommodation.setNewSearchRequest({
        ...body,
        destination: values.destinationInput,
        adultsTotal: countPassengers(values, 'adultsNumber'),
        childrenTotal: countPassengers(values, 'childrenNumber'),
        isSearchForAgency: isAgencySearch,
        balance: values.balance,
    });

    navigate(isAgencySearch ? '/agency-search/results' : '/search/results');
};

export const searchCreate = () => {
    if (!$accommodation.search.request) {
        return null;
    }
    const {
        destination,
        adultsTotal,
        childrenTotal,
        surrogateAgencyName,
        surrogateAgentName,
        surrogateActiveAgencyContractKind,
        ...body
    } = $accommodation.search.request;

    return API.post({
        url: apiMethods.A_SEARCH_ONE_CREATE,
        body,
        success: (result) => $accommodation.setSearchId(result),
        error: () => $accommodation.updateSearchResultStatus({ taskState: SEARCH_STATUSES.BROKEN }),
    });
};
