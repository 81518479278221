import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CachedForm, FORM_NAMES } from 'components/form';
import { accommodationSearchValidator } from 'components/form/validation';
import { searchSaveForm } from 'tasks/accommodation/search-create';
import { searchFormValuesCorrection } from 'tasks/accommodation/search-form-formatter';
import { $auth } from 'stores';

const SearchFormWrapper = observer(({ isAgencySearch, Form, fullsize }) => {
    const navigate = useNavigate();

    return (
        <div className="search block">
            <div className="hide">{JSON.stringify($auth.settings)}</div>
            <CachedForm
                id={isAgencySearch ? FORM_NAMES.AgenciesSearchForm : FORM_NAMES.SearchForm}
                initialValues={{
                    destination: '',
                    destinationInput: '',
                    residency: '',
                    residencyCode: '',
                    nationality: '',
                    nationalityCode: '',
                    checkInDate: null,
                    checkOutDate: null,
                    roomDetails: [
                        {
                            adultsNumber: 2,
                            childrenAges: [],
                        },
                    ],
                }}
                valuesOverwrite={(values) => searchFormValuesCorrection(values, isAgencySearch)}
                validationSchema={accommodationSearchValidator}
                onSubmit={(values) => {
                    searchSaveForm(values, navigate, fullsize, isAgencySearch);
                }}
                render={(formik) => Form({ formik, fullsize })}
            />
        </div>
    );
});

export default SearchFormWrapper;
