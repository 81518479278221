import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import ModalTemplate from 'common/template/modal-template';
import Map from './map';
import { customIcon } from './custom/custom-icon';
import MapMarkerPopup from './map-marker-popup';
import { $accommodation } from 'stores';
import { Loader } from '../../../components/simple';

const SearchMapModal = observer((props) => {
    const [lastLoadedCount, setLastLoadedCount] = useState(0);
    const [points, setPoints] = useState(null);
    const [searchIdNotified, setSearchIdNotified] = useState(null);

    const { search } = $accommodation;

    useEffect(() => {
        if (props.isOpen && searchIdNotified !== search?.id) {
            API.get({
                url: apiMethods.A_SEARCH_ONE_MAP_OPEN(search.id),
            });
            setSearchIdNotified(search?.id);
        }
    }, [props.isOpen, search?.id, searchIdNotified, setSearchIdNotified]);

    useEffect(() => {
        if (props.isOpen) {
            const currentCount = search.resultCount;
            if (!points || lastLoadedCount < currentCount) {
                API.get({
                    url: apiMethods.A_SEARCH_ONE_MAP_POINTS(search.id),
                    success: (data) => {
                        if (currentCount >= lastLoadedCount) {
                            setLastLoadedCount(currentCount);
                            setPoints(data);
                        }
                    },
                });
            }
        }
    }, [props.isOpen, search.taskState, points, lastLoadedCount]);

    if (!props.isOpen) {
        return null;
    }

    if (!points?.length) {
        return <Loader page />;
    }

    return (
        <ModalTemplate addClass="map-modal" withCross={false} {...props}>
            <div className="button small close-button shadow" onClick={props.onClose}>
                <span className="icon icon-close" /> Close Map
            </div>
            <Map
                center={
                    // todo: make centring better
                    [points[0].coordinates.latitude, points[0].coordinates.longitude]
                }
                searchId={search?.id}
            >
                <MarkerClusterGroup showCoverageOnHover={false}>
                    {points.map((item) => (
                        <Marker
                            position={[item.coordinates.latitude, item.coordinates.longitude]}
                            key={item.accommodationHtId}
                            icon={customIcon}
                        >
                            <MapMarkerPopup item={item} />
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </Map>
        </ModalTemplate>
    );
});

export default SearchMapModal;
