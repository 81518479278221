import React from 'react';
import Tiles from 'components/tiles';
import Search from 'pages/accommodation/search-form/search-form';
import { observer } from 'mobx-react';
import ItineraryNotification from './custom-search/itinerary-notification';

const AccommodationTitlePage = observer(({ noSearch }) => (
    <>
        <ItineraryNotification />
        {!noSearch && (
            <div className="search-fullsize-wrapper">
                <Search fullsize key="search-full" />
            </div>
        )}
        <div className="tiles block">
            <section>
                <h2>Exclusive offers</h2>
                <Tiles
                    list={[
                        {
                            title: 'Atlantis The Royal, Dubai',
                            image: '/images/hotels/exclusive-offers/atlantis.jpg',
                            destinationName: 'Atlantis The Royal, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27025595',
                        },
                        {
                            title: 'The Dubai Edition',
                            image: '/images/hotels/exclusive-offers/edition.jpg',
                            destinationName: 'The Dubai Edition, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27010249',
                        },
                        {
                            title: 'Radisson Blu Resort Maldives',
                            image: '/images/hotels/exclusive-offers/radisson-blu-maldives.jpg',
                            destinationName: 'Radisson Blu Resort Maldives, Malé City, Maldives',
                            htId: 'Accommodation_27015324',
                        },
                        {
                            title: 'Le Royal Meridien Beach Resort And Spa Dubai',
                            image: '/images/hotels/exclusive-offers/le-meridien.jpg',
                            destinationName:
                                'Le Royal Meridien Beach Resort And Spa Dubai, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27012144',
                        },
                    ]}
                />
                <h2>Our Latest Direct Partnerships</h2>
                <Tiles
                    list={[
                        {
                            city: 'Caravan AlUla By Our Habitas, Saudi Arabia',
                            image: '/images/hotels/partnership/caravan-habitas-al-ula.jpg',
                            destinationName: 'Caravan AlUla By Our Habitas, Al Ula, Saudi Arabia',
                            htId: 'Accommodation_27455939',
                        },
                        {
                            city: 'Our Habitas AlUla, Saudi Arabia',
                            image: '/images/hotels/partnership/habitas-al-ula.jpg',
                            destinationName: 'Our Habitas AlUla, Al Ula, Saudi Arabia',
                            htId: 'Accommodation_27313274',
                        },
                    ]}
                />
                <Tiles
                    list={[
                        {
                            city: 'Jumeirah Muscat Bay',
                            image: '/images/hotels/partnership/jumeirah-muscat-bay.jpg',
                            destinationName: 'Jumeirah Muscat Bay, Muscat, Oman',
                            htId: 'Accommodation_27009610',
                        },
                        {
                            city: 'Bahi Ajman Palace Hotel',
                            image: '/images/hotels/partnership/bahi-ajman-palace.jpg',
                            destinationName: 'Bahi Ajman Palace Hotel, Ajman, United Arab Emirates',
                            htId: 'Accommodation_27018737',
                        },
                        {
                            city: 'Coral Beach Resort Sharjah',
                            image: '/images/hotels/partnership/coral-beach-resort.jpg',
                            destinationName: 'Coral Beach Resort Sharjah, Sharjah, United Arab Emirates',
                            htId: 'Accommodation_27018742',
                        },
                    ]}
                />
            </section>
        </div>
    </>
));

export default AccommodationTitlePage;
