import React from 'react';
import Popover from 'components/simple/popover';

const SearchResultMarkup = ({ isAgencySearch, totalMarkupPercent, appliedMarkups }) => {
    const totalMarkup = totalMarkupPercent.toFixed(2);

    if (!appliedMarkups?.length || !isAgencySearch) {
        return <div className="note">Markup: {totalMarkup}%</div>;
    }

    const AppliedMarkups = appliedMarkups?.length && (
        <div>
            <p>Applied Markup:</p>
            {appliedMarkups.map(({ description, percent }, index) => (
                <p className="list" key={index}>
                    {description} {percent}%
                </p>
            ))}
        </div>
    );

    return (
        <Popover title={`Total Markup = ${totalMarkup}%`} Content={AppliedMarkups}>
            <div className="text-markup note">Markup: {totalMarkup}%</div>
        </Popover>
    );
};

export default SearchResultMarkup;
