import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, Link } from 'react-router-dom';
import { API } from 'htcore';
import { SEARCH_STATUSES } from 'enum';
import { date } from 'simple';
import apiMethods from 'common/api-methods';
import { subscribeScroller } from 'common/misc/scroller';
import { Loader } from 'components/simple';
import {
    searchSecondStepLoadWithNewFilters,
    subscribeSearchRoomsResults,
} from 'tasks/accommodation/search-rooms-loaders';
import RoomContractSetGroup from './room-contract-set-group';
import { $accommodation, $notifications } from 'stores';

const RoomContractSetsList = observer(
    ({ isAgencySearch, expandedGroups, setExpandedGroups, setFiltersLastUpdated }) => {
        const [loading, setLoading] = useState(false);
        const navigate = useNavigate();
        const { hasAnyFilters, selected } = $accommodation;
        const { filters } = selected;
        const selectedAccommodation = $accommodation.selected.accommodation;
        const { roomsTaskState, isLoadingRooms } = $accommodation.search;

        useEffect(() => {
            const interval = subscribeSearchRoomsResults();
            return () => {
                clearInterval(interval);
            };
        }, []);
        useEffect(subscribeScroller, []);

        const clearFilters = () => {
            searchSecondStepLoadWithNewFilters(null);
            setFiltersLastUpdated(Number(new Date()));
        };

        const getContractSetData = (roomContractSet) => {
            API.post({
                url: apiMethods.A_SEARCH_STEP_THREE(
                    $accommodation.search.id,
                    selectedAccommodation.htId,
                    roomContractSet.id
                ),
                body: {
                    roomContractSetCode: roomContractSet.roomContractSetCode,
                    ...($accommodation.search.request.surrogateAgencyId
                        ? {
                              surrogateAgencyId: $accommodation.search.request.surrogateAgencyId,
                              surrogateAgentId: $accommodation.search.request.surrogateAgentId,
                              currency: $accommodation.search.request.currency,
                              isSearchForAgency: isAgencySearch,
                          }
                        : {}),
                },
                success: (result) => {
                    if (!result?.availabilityId) {
                        $notifications.addNotification(
                            'Sorry, this room is not available now, try again later'
                        );
                        return;
                    }
                    $accommodation.selectRoomContractSet(result, roomContractSet);
                    !isAgencySearch && navigate('/accommodation/booking');
                },
                after: () => setLoading(false),
                error: () => {
                    $accommodation.selectRoomContractSet(null, null);
                },
            });
        };

        //todo: move to booking logic block
        const roomContractSetSelect = (roomContractSet) => {
            if (isAgencySearch) {
                getContractSetData(roomContractSet);
                return;
            }

            setLoading(true);
            getContractSetData(roomContractSet);
        };

        // todo: move to search logics, applyFiltersSecondStep
        const roomContractSets = useMemo(() => {
            if (filters?.isOnlyRefundable) {
                return selectedAccommodation?.roomContractSets
                    ?.map((set) => ({
                        ...set,
                        roomContractSets: set.roomContractSets.filter(
                            (item) =>
                                !item.isNonRefundable &&
                                (item.deadline.date ? date.isFuture(item.deadline.date) : true)
                        ),
                    }))
                    .filter((set) => set.roomContractSets.length);
            }

            if (filters?.category) {
                return selectedAccommodation?.roomContractSets
                    ?.map((set) => ({
                        ...set,
                        roomContractSets: set.roomContractSets.filter(
                            (item) =>
                                !!item.rooms.find((room) =>
                                    room.contractName.toLowerCase().includes(filters.category.toLowerCase())
                                )
                        ),
                    }))
                    .filter((set) => set.roomContractSets.length);
            }

            return selectedAccommodation?.roomContractSets.filter((set) => set.roomContractSets.length);
        }, [filters, selectedAccommodation]);

        return (
            <div className="rooms">
                {(loading || isLoadingRooms || (!roomContractSets?.length && !hasAnyFilters)) && (
                    <Loader page={loading} />
                )}
                {!isLoadingRooms &&
                    roomContractSets?.map((roomContractSetsGroup, index) => (
                        <RoomContractSetGroup
                            isAgencySearch={isAgencySearch}
                            expandedGroups={expandedGroups}
                            setExpandedGroups={setExpandedGroups}
                            key={`${roomContractSetsGroup.groupName}-${index}`}
                            group={roomContractSetsGroup}
                            roomContractSetSelect={roomContractSetSelect}
                            groupsCount={roomContractSets.length}
                        />
                    ))}

                {SEARCH_STATUSES.isFinished(roomsTaskState) && !roomContractSets?.length && (
                    <>
                        {!isLoadingRooms && hasAnyFilters && (
                            <div className="error-page inside">
                                <div>
                                    <div className="text">
                                        <h1>No rooms match selected filters</h1>
                                        <p>Try to set less strict conditions</p>
                                    </div>

                                    <button className="button" onClick={clearFilters}>
                                        Clear Filters
                                    </button>
                                </div>
                            </div>
                        )}
                        {!isLoadingRooms && !hasAnyFilters && (
                            <div className="error-page inside">
                                <div>
                                    <div className="text">
                                        <h1>No rooms available</h1>
                                        <p>
                                            Unfortunately, we have not found any rooms available, please try
                                            again
                                        </p>
                                    </div>

                                    <Link
                                        className="button"
                                        to={isAgencySearch ? '/agency-search/results' : '/search/results'}
                                    >
                                        View Other Options
                                    </Link>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
);

export default RoomContractSetsList;
