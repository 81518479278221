import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { date, remapStatus } from 'simple';
import { Loader } from 'components/simple';

const GetRefCodeEvents = {
    AddAgencyReferenceCode: (code) => (
        <>
            <b>Added Agency Reference Code </b> {code}
        </>
    ),
    RemoveAgencyReferenceCode: (_, code) => (
        <>
            <b>Removed Agency Reference Code</b> {code}
        </>
    ),
    EditAgencyReferenceCode: (newCode, oldCode) => (
        <>
            <b> Edited Agency Reference Code</b> from {oldCode} to {newCode}
        </>
    ),
};

const BookingHistoryView = ({ isService, booking }) => {
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState(null);
    const loadBookingHistory = () => {
        API.get({
            url: booking?.isOffline
                ? apiMethods.OFFLINE_BOOKING_HISTORY(booking.bookingId)
                : apiMethods.BOOKING_HISTORY(booking.bookingId, isService),
            success: (data) => data && setHistory(data.reverse()),
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        loadBookingHistory();
    }, [booking]);

    if (!booking?.bookingId) return null;

    if (loading) return <Loader />;

    if (history?.length < 2) return null;

    return (
        <div className="history-status">
            <h3>History of Booking Status Changes</h3>
            <ol reversed>
                {history?.map((item) => {
                    const isRefCodeChanges = item.reason === 'AgencyReferenceCode';

                    return (
                        <li key={item.createdAt}>
                            <b>{date.format.a(item.createdAt)}</b>
                            <span style={{ color: '#777' }}>
                                {' '}
                                at {date.format.timeSeconds(item.createdAt)}.{' '}
                            </span>

                            <b>
                                {item.event === 'CancelRoom'
                                    ? 'Room Cancelled'
                                    : remapStatus(item.status || '')}
                            </b>

                            {GetRefCodeEvents?.[item.event]?.(item.newValue, item.oldValue)}

                            {item.agentName ? (
                                <> by {item.agentName}</>
                            ) : (
                                <>{item.adminName && <b> by Admin: {item.adminName}</b>}</>
                            )}
                            {item.event === 'Create' && booking.surrogateAgencyName
                                ? ` for ${booking.surrogateAgentName ? 'Active' : 'Surrogate'} Agency ${
                                      booking.surrogateAgencyName
                                  }${booking.surrogateAgentName ? `, ${booking.surrogateAgentName}` : ''}`
                                : null}
                            {Boolean(item.reason) && !isRefCodeChanges && <> (Reason: {item.reason})</>}
                            {item.event && !isRefCodeChanges && (
                                <>
                                    {' '}
                                    (Event: <b>{item.event}</b>)
                                </>
                            )}
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default BookingHistoryView;
