import React from 'react';
import AgencySearchForm from './agency-search-form';
import SearchFormWrapper from '../search-form/search-form-wrapper';

const AgencySearch = () => (
    <section className="agency-search">
        <SearchFormWrapper isAgencySearch Form={(props) => <AgencySearchForm {...props} />} />
    </section>
);

export default AgencySearch;
