import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { SEARCH_STATUSES } from 'enum';
import {
    searchLoadPage,
    searchLoadWithNewFilters,
    searchLoadWithNewOrder,
    PAGE_SIZE,
} from 'tasks/accommodation/search-loaders';
import { searchRoomsCreate } from 'tasks/accommodation/search-rooms-loaders';
import { useTitle } from 'htcore';
import { Loader } from 'components/simple';
import SearchFilters from 'pages/accommodation/search-filters/search-filters';
import SearchNothingFound from './search-nothing-found';
import AccommodationTitlePage from '../title';
import AccommodationSearchResultContract from './search-result-contract';
import SearchBigLoader from './search-big-loader';
import CustomSearchNotification from '../custom-search-notification';
import { $accommodation, $ui } from 'stores';
import Pager from './pager';
import AccommodationFiltersForm from '../search-filters/accommodation-filters-form';
import {
    useSearchIntervalUpdater,
    useSearchSocketConnection,
} from '../../../tasks/accommodation/search-loader-hooks';
import AgencySearch from '../agency-search/agency-search';
import SearchHeading from './search-heading';

const AccommodationSearchResultsPage = observer(({ isAgencySearch }) => {
    useTitle('Search Results');
    const [filtersLastUpdated, setFiltersLastUpdated] = useState(0);
    const navigate = useNavigate();

    useSearchIntervalUpdater();
    useSearchSocketConnection();

    const clearFilters = () => {
        searchLoadWithNewFilters(null);
        setFiltersLastUpdated(Number(new Date()));
    };

    const { search, hotelArray, hasAnyFilters } = $accommodation;
    const { taskState, request } = search;

    useEffect(() => {
        if (!request?.destination || !search.taskState) {
            navigate(isAgencySearch ? '/agency-search' : '/search');
        }
    }, []);

    if (
        !request?.destination ||
        !search.taskState ||
        (!search.id && SEARCH_STATUSES.BROKEN === search.taskState)
    )
        return <AccommodationTitlePage noSearch />;

    document.title = request.destination + ' – ' + 'Happytravel.com';

    if (search.result.length === 1 && request?.htIds?.[0].includes('Accommodation_')) {
        searchRoomsCreate(search.result[0], navigate, isAgencySearch);
        return <SearchBigLoader key={search.createdAt} destination={search.request.destination} />;
    }

    if (
        taskState !== SEARCH_STATUSES.BROKEN &&
        !search.resultCount &&
        (!SEARCH_STATUSES.isReadyToLoad(taskState) ||
            (!search.result.length && SEARCH_STATUSES.PARTIALLY_COMPLETED === taskState))
    )
        return (
            <SearchBigLoader
                key={search.createdAt}
                destination={search.request.destination}
                customTitle={request?.htIds?.[0].includes('Accommodation_') && 'Searching for rooms'}
            />
        );

    const pageCount = Math.ceil((search.availabilityCount || search.resultCount) / PAGE_SIZE);
    const SearchPaging = hotelArray.length ? (
        <Pager pageCount={pageCount} currentPage={search.page} action={searchLoadPage} />
    ) : null;

    return (
        <>
            {isAgencySearch && <AgencySearch />}
            <div
                className={
                    'search-results first-step block' +
                    ($ui.itnMode || $accommodation.search?.request?.surrogateAgencyId ? ' with-itn' : '')
                }
            >
                {!isAgencySearch && (
                    <div className="notification-wrapper">
                        <CustomSearchNotification />
                    </div>
                )}

                <div data-is-agency-search={isAgencySearch} className="heading-section">
                    <section>
                        {search.loading && <Loader page />}

                        <SearchHeading
                            isAgencySearch={isAgencySearch}
                            search={search}
                            request={request}
                            taskState={taskState}
                        />

                        <div className="upper-part">
                            <div className="left">
                                {!!search.result.length || hasAnyFilters ? (
                                    <SearchFilters
                                        key={filtersLastUpdated}
                                        update={setFiltersLastUpdated}
                                        filterAction={searchLoadWithNewFilters}
                                        sorterAction={searchLoadWithNewOrder}
                                    />
                                ) : null}
                            </div>
                            <div data-is-agency-search={isAgencySearch} className="right">
                                {SearchPaging}
                            </div>
                        </div>
                    </section>
                </div>
                <section className="two-column-wrapper">
                    <div data-is-agency-search={isAgencySearch} className="column left">
                        {!!search.result.length || hasAnyFilters ? (
                            <AccommodationFiltersForm
                                isAgencySearch={isAgencySearch}
                                key={filtersLastUpdated}
                                filterAction={searchLoadWithNewFilters}
                            />
                        ) : null}
                    </div>

                    <div data-is-agency-search={isAgencySearch} className="column right">
                        <SearchNothingFound
                            isAgencySearch={isAgencySearch}
                            resultCount={search.resultCount}
                            filters={hasAnyFilters}
                            clearFilters={clearFilters}
                            condition={!hotelArray.length && !search.loading}
                        />
                        {hotelArray.length || search.loading ? (
                            <div className="results">
                                {hotelArray.map((item, index) => (
                                    <AccommodationSearchResultContract
                                        isAgencySearch={isAgencySearch}
                                        key={`${item.htId}-${index}`}
                                        contract={item}
                                        setFiltersLastUpdated={setFiltersLastUpdated}
                                    />
                                ))}

                                {hotelArray.length < PAGE_SIZE &&
                                !SEARCH_STATUSES.isFinished(search.taskState) ? (
                                    <Loader />
                                ) : null}

                                {pageCount - 1 === search.page &&
                                    pageCount &&
                                    SEARCH_STATUSES.isFinished(search.taskState) && (
                                        <div className="finish">
                                            {search.availabilityCount} found
                                            {search.availabilityCount &&
                                            search.availabilityCount !== search.resultCount
                                                ? ` of ${search.resultCount} total`
                                                : ''}
                                        </div>
                                    )}

                                {SearchPaging}
                            </div>
                        ) : null}
                    </div>
                </section>
            </div>
        </>
    );
});

export default AccommodationSearchResultsPage;
