import React from 'react';
import { observer } from 'mobx-react';
import AccommodationSearchFilters from './accommodation-filters';
import AccommodationSearchSorters from './accommodation-sorters';
import AccommodationSearchRefundableFilter from './accommodation-refundable-filter';
import AccommodationExpandRoomGroups from './accommodation-expand-rooms-groups';
import AccountBalancePlate from './account-balance-plate';
import MapButton from '../map/map-button';

const SearchFilters = observer(
    ({ filterAction, sorterAction, update, secondStep, showExpand, expandedGroups, setExpandedGroups }) => {
        return (
            <div className="filtration">
                <AccommodationSearchFilters
                    update={update}
                    filterAction={filterAction}
                    secondStep={secondStep}
                />
                <AccommodationSearchSorters sorterAction={sorterAction} />
                {!secondStep && (
                    <div className="item hide-desktop">
                        <MapButton />
                    </div>
                )}
                {secondStep && showExpand && (
                    <AccommodationExpandRoomGroups
                        expandedGroups={expandedGroups}
                        setExpandedGroups={setExpandedGroups}
                    />
                )}
                <AccommodationSearchRefundableFilter secondStep={secondStep} />
                {secondStep && <AccountBalancePlate className="item" />}
            </div>
        );
    }
);

export default SearchFilters;
