import React from 'react';
import { observer } from 'mobx-react';
import SearchFormFullsizePart from './search-form-fullsize';
import SearchFormShortPart from './search-form-short';
import SearchFormWrapper from './search-form-wrapper';

const Form = ({ fullsize, formik }) => {
    return !fullsize ? (
        <SearchFormShortPart formik={formik} />
    ) : (
        <section>
            <SearchFormFullsizePart formik={formik} />
        </section>
    );
};

const AccommodationSearchForm = observer(({ fullsize }) => {
    return <SearchFormWrapper Form={Form} fullsize={fullsize} />;
});

export default AccommodationSearchForm;
