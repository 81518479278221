import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import SimpleHeader from 'common/template/header/simple-header';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { PAYMENT_PROCESSORS } from 'enum';
import { date, price } from 'simple';
import { Loader } from 'components/simple';
import { windowLocalStorage } from 'htcore/misc/window-local-storage';
import BillingDetailsForm from '../parts/billing-details-form';
import ReactTooltip from 'react-tooltip';
import { loadDirectPaymentServiceData } from 'tasks/payment/direct/direct-service';
import { postVirtualForm } from 'tasks/payment/submitter';
import { $payment } from 'stores';

const PaymentDirectLinkPage = observer(() => {
    const [order, setOrder] = useState(null);
    const [processor, setProcessor] = useState(null);
    const navigate = useNavigate();
    const { orderCode } = useParams();

    useEffect(() => {
        const load = async () => {
            const loadedOrder = await API.get({
                noAuth: true,
                url: apiMethods.DIRECT_LINK_PAY.GET_INFO(orderCode),
            });
            $payment.setSubject(loadedOrder.referenceCode, {
                amount: loadedOrder.amount,
                currency: loadedOrder.currency,
            });

            let selectedProcessor = loadedOrder.paymentProcessor;
            if (!(selectedProcessor in PAYMENT_PROCESSORS)) {
                selectedProcessor = await API.get({
                    noAuth: true,
                    url: apiMethods.PAYMENT_PROCESSOR,
                });
            }
            setProcessor(selectedProcessor);

            if (PAYMENT_PROCESSORS.Payfort === selectedProcessor) {
                const service = await loadDirectPaymentServiceData();
                $payment.setService(service, loadedOrder.referenceCode);
            }
            windowLocalStorage.set(loadedOrder.referenceCode, orderCode);

            if ('Success' === loadedOrder.creditCardPaymentStatus) {
                $payment.setPaymentResult('Success');
                navigate('/pay/confirmation');
                return;
            }
            setOrder(loadedOrder);
        };
        load();
    }, []);

    const indirectPay = async (values, onFailure) => {
        if (processor === PAYMENT_PROCESSORS.NGenius) {
            API.post({
                noAuth: true,
                url: apiMethods.DIRECT_LINK_PAY.NGENIUS_PAY(orderCode),
                body: {
                    emailAddress: values.emailAddress,
                    billingAddress: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                    },
                },
                success: ({ paymentLink }) => {
                    window.location.href = paymentLink;
                },
                error: onFailure,
            });
        } else if (PAYMENT_PROCESSORS.PayTabs === processor) {
            API.post({
                noAuth: true,
                url: apiMethods.DIRECT_LINK_PAY.PAYTABS_PAY(orderCode),
                body: {
                    email: values.emailAddress,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    address: values.address,
                    city: values.city,
                },
                success: ({ paymentLink }) => {
                    window.location.href = paymentLink;
                },
                error: onFailure,
            });
        } else if (PAYMENT_PROCESSORS.Payfort === processor) {
            const {
                accessCode: access_code,
                merchantIdentifier: merchant_identifier,
                payfortRedirectionUrl,
            } = await loadDirectPaymentServiceData();

            const body = {
                command: 'PURCHASE',
                access_code,
                merchant_identifier,
                merchant_reference: $payment.subject.referenceCode,
                settlement_reference: $payment.subject.referenceCode,
                amount: Math.round(order.amount * 100),
                currency: order.currency,
                language: 'en',
                customer_email: order.email,
            };

            const signature = await API.post({
                noAuth: true,
                url: apiMethods.DIRECT_LINK_PAY.SIGN,
                body,
            });

            postVirtualForm(payfortRedirectionUrl, { ...body, signature });
        }
    };

    return (
        <>
            <SimpleHeader />
            {!order ? (
                <Loader />
            ) : (
                <div className="payment block">
                    <section>
                        <h2 style={{ marginTop: 50 }}>
                            Payment order {order.referenceCode}
                            <br />
                            {date.format.a(order.date)}
                        </h2>
                        {!!order.comment && (
                            <div className="accent-frame">
                                <div className="data only">
                                    {order.comment.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                        {PAYMENT_PROCESSORS.Payfort !== processor ? (
                            <>
                                <h2>Please Enter Your Billing Details</h2>
                                <BillingDetailsForm
                                    pay={indirectPay}
                                    total={$payment.subject.price}
                                    email={order.email}
                                    withAddress={PAYMENT_PROCESSORS.PayTabs === processor}
                                />
                            </>
                        ) : (
                            <button
                                onClick={() => indirectPay()}
                                className="button main"
                                style={{ marginTop: -15 }}
                            >
                                <span className="icon icon-white-lock" />
                                {'Pay' + price($payment.subject.price)}
                            </button>
                        )}
                    </section>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                </div>
            )}
        </>
    );
});

export default PaymentDirectLinkPage;
