import React, { useEffect, useState } from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';
import settings from 'settings';
import { authorized } from 'htcore';
import { OidcInstance } from 'htcore/auth';
import { WMSTileLayerWithHeader } from './custom/tile-with-headers';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

const defaultPosition = [25.2048, 55.2708];

const Map = ({ children, center, searchId, whenReady }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (!authorized()) return;
        OidcInstance()
            .getUser()
            .then((user) => user?.access_token && setToken(user.access_token));
    }, [authorized()]);

    useEffect(() => {
        new Array(document.querySelector('.leaflet-control-attribution')).forEach((item) => {
            item?.parentNode.removeChild(item);
        });
    }, []);

    if (!token) {
        return null;
    }

    return (
        <MapContainer
            center={center || defaultPosition}
            zoom={13}
            scrollWheelZoom={true}
            zoomControl={false}
            whenReady={whenReady}
        >
            <WMSTileLayerWithHeader
                attribution={null}
                url={`${settings.edo}maps/{z}/{x}/{y}`}
                format="image/png"
                minZoom={1}
                maxZoom={20}
                headers={{
                    Authorization: `Bearer ${token}`,
                    searchId: searchId,
                }}
            />
            <ZoomControl position="topright" />
            {children}
        </MapContainer>
    );
};

export default Map;
