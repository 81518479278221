import React, { useState } from 'react';
import SearchMapModal from './search-map-modal';
const MapButton = () => {
    const [modalVisibility, setModalVisibility] = useState(false);

    const openMap = () => setModalVisibility(true);

    return (
        <div className="map-button-holder">
            <div className="map-button-image" onClick={openMap}>
                <img src="/images/search/world-map.svg" alt="" />
            </div>
            <button type="button" className="button" onClick={openMap}>
                View On Map
            </button>
            <SearchMapModal isOpen={modalVisibility} onClose={() => setModalVisibility(false)} />
        </div>
    );
};

export default MapButton;
