import React, { useEffect } from 'react';
import { Loader } from 'components/simple';
import ModalTemplate from 'common/template/modal-template';
import BookingSummary from './booking-summary';
import { $accommodation } from 'stores';

const FinalPriceModalWindow = ({ onClose }) => {
    const accommodation = $accommodation.selected.accommodationFinal?.accommodation,
        baseInfo = $accommodation.selected.accommodationFinal,
        contract = $accommodation.selected.roomContractSet,
        request = $accommodation.search.request,
        isReturnsContractSetError = $accommodation.selected.isReturnsContractSetError;

    useEffect(() => {
        isReturnsContractSetError && onClose();
    }, [isReturnsContractSetError]);

    const isCreditCardPayment = request?.surrogateActiveAgencyContractKind === 'CreditCardPayments';

    return (
        <ModalTemplate isOpen withCross onClose={onClose}>
            {$accommodation.selected.roomContractSet ? (
                <div className="billet final-price-modal">
                    <BookingSummary
                        isCreditCardPayment={isCreditCardPayment}
                        details={accommodation}
                        contract={contract}
                        checkInDate={baseInfo?.checkInDate}
                        checkOutDate={baseInfo?.checkOutDate}
                        numberOfNights={$accommodation.search.numberOfNights}
                        numberOfGuests={request?.adultsTotal + request?.childrenTotal}
                    />
                </div>
            ) : (
                <div style={{ minWidth: '420px', minHeight: '650px' }}>
                    <Loader segment />
                </div>
            )}
        </ModalTemplate>
    );
};

export default FinalPriceModalWindow;
