import React, { useEffect } from 'react';
import { getQueryParams, useTitle } from 'htcore';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { PAYMENT_PROCESSORS } from 'enum';
import { Loader } from 'components/simple';
import { windowLocalStorage } from 'htcore/misc/window-local-storage';
import { paymentCallback } from 'tasks/payment/finalize';
import { directPaymentCallback } from 'tasks/payment/direct/direct-finalize';
import { useNavigate } from 'react-router-dom';
import { $payment } from 'stores';

const getIsMealService = (refCode) => refCode.includes('MPS');

const Payment3DSecureCallbackPage = () => {
    useTitle('Processing');
    const navigate = useNavigate();
    const params = getQueryParams();
    const referenceCode = params.merchant_reference || params.referenceCode;
    const directLinkCode = windowLocalStorage.get(referenceCode);

    useEffect(() => {
        if (directLinkCode) {
            API.get({
                noAuth: true,
                url: apiMethods.DIRECT_LINK_PAY.GET_INFO(directLinkCode),
                success: (loadedOrder) => {
                    let selectedProcessor = loadedOrder.paymentProcessor;
                    let processor;
                    if (selectedProcessor) {
                        processor = selectedProcessor;
                    }

                    if (PAYMENT_PROCESSORS.NGenius === processor) {
                        API.post({
                            noAuth: true,
                            url: apiMethods.DIRECT_LINK_PAY.NGENIUS_STATUS(directLinkCode),
                            after: (data, error) => directPaymentCallback(data, error, navigate),
                        });
                    } else if (PAYMENT_PROCESSORS.Payfort === processor) {
                        directPaymentCallback(params, null, navigate);
                    } else if (PAYMENT_PROCESSORS.PayTabs === processor) {
                        directPaymentCallback(params, null, navigate);
                    }
                },
            });
            return;
        }

        if (referenceCode) {
            API.get({
                url: apiMethods.BOOKING_PAYMENT_PROCESSOR(referenceCode),
                success: (processor) => {
                    if (PAYMENT_PROCESSORS.NGenius === processor) {
                        API.post({
                            url: apiMethods.NGENIUS_STATUS(
                                $payment.subject.referenceCode,
                                getIsMealService($payment.subject.referenceCode)
                            ),
                            after: (data, error) => paymentCallback(data, error, navigate),
                        });
                    } else if (PAYMENT_PROCESSORS.PayTabs === processor) {
                        paymentCallback(params, null, navigate);
                    } else if (PAYMENT_PROCESSORS.Payfort === processor) {
                        paymentCallback(params, null, navigate);
                    }
                },
            });
        } else {
            paymentCallback(params, params?.message ? params.message : null, navigate);
        }
    }, []);

    return (
        <>
            <Loader white page />
            {__devEnv && (
                <div className="development-block">
                    <a
                        className="button"
                        href={'http://localhost:4000' + window.location.pathname + window.location.search}
                    >
                        Process to localhost
                    </a>
                </div>
            )}
        </>
    );
};

export default Payment3DSecureCallbackPage;
